import {
	AcademicCapIcon,
  } from '@heroicons/react/solid'
  
  const obj = {
	title: "Handwriting to Text Conversion",
	desc: "Use this tool to convert PDFs of handwritten scripts into fully editable text",
	category: "Transcribe",
	Icon: AcademicCapIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "blue-400",
	toColor: "blue-600",
  
	to: "/ai/writing/transcribe",
	api: "/ai/writing/transcribe",
  
	output: {
	  title: "Handwriting to Text Conversion",
	  desc: "Please wait as we query AI models. This can take up to 2 minutes.",
	  // Icon: RefreshIcon,
	  // color: "",
	},
  
	prompts: [{
	  title: "Handwriting to Text Conversion",
	  desc: "Enter the candidate's response",
	  // n: 1,
	  prompts: [{
		title: "Context",
		attr: "essay_title",
		value: "",
		placeholder: "Tell us a little bit about this document - perhaps the title of the piece of work, or what it is about.",
		label: "Essay Title",
		type: "textarea",
		maxLength: 1050,
		required: true,
		example: "Compare how the effects of threats and violence are presented in these two extracts. You should consider:\n\n• the situations faced by the victims of threats and violence\n• how the victims react to their situations \n• how the writers’ use of language and techniques creates effects."
	},

	{
		title: "Handwritten Script (Upload .pdf of the work).",
		name: "essays", // This should match the multer configuration on the server
		attr: "essays", // This should match the multer configuration on the server
		value: "",
		label: "Upload your PDF file",
		type: "file",
		required: true,
		error: "",
	  },
		
	  ],
	  example: {
	  output: "Percentage likelihood the student's response has been AI generated: 0%\nAO1 Score: 10.6\nAO2 Score: 10.4\nAO3 Score: 4\nTotal Score: 25.0 / 30\nFeedback: \nAO1:\n\n> social attitudes towards class and social hierarchy. \n\nEND OF RESPONSE.",      // outputs: [],
	  // Icon: RefreshIcon,
	  // color: "",
	}
  }]
	
  }
  
  export default obj