import axios from "axios";
import { observable, computed, makeObservable, runInAction } from "mobx";
import { configure } from "mobx";
import FuzzySet from "fuzzyset";
import Filter from "bad-words";
import { handwritingConfig } from "./HandwritingConfig";
import TOOLS from "./tools";
import config from "./config";

let filterBadWords = new Filter();
let baseURL = config.baseURL;

configure({ enforceActions: "never" });

let api = axios.create({ baseURL });

const FuzzySearch = FuzzySet([...TOOLS.map((tool) => tool.title)]);

class AppStore {
  api = api;
  @observable baseURL = baseURL;
  @observable redirect = "";
  @observable editor = null;
  @observable editorIsLoading = true;

  // User Profile
  @observable profile = {};
  @observable isLoggedIn = false;
  @observable loginLoading = false;
  @observable landingPageUrl = config.landingPageUrl;
  @observable jwtToken = null;

  @observable referral = "";
  @observable toolsKeyword = "";
  @observable tools = TOOLS; // Add this line to initialize tools with TOOLS
  @observable loadingTools = false;
  @observable error = "";
  @observable copyToClipboardText = "";
  @observable feedback = "";

  constructor() {
    makeObservable(this);
    this.init();

    // Check credits every time, and log out people who aren't authenticated
    this.api.interceptors.response.use(
      (response) => {
        this.updateCredits(response);
        return response;
      },
      (error) => {
        console.log(error);
        console.log("error.response.statusText", error.response.statusText);
        if (error.response && error.response.statusText === "Token Authentication Failed") {
          this.handleLogout();
        }
        if (error.response && error.response.statusText === "No Credit Remaining") {
          this.noCreditsRemainPrompt();
        }
        return Promise.reject(error);
      }
    );
  }

  getConfig(pathname, isHandwritingModal) {
    if (isHandwritingModal) {
      return handwritingConfig;
    }

    const toolConfig = TOOLS.find((tool) => tool.to === pathname);
    if (toolConfig) {
      return toolConfig;
    }

    return {}; // Return default config if no tool is found for the given pathname
  }

  noCreditsRemainPrompt = () => {
    window.location.pathname = "/my-profile";
  };

  init = async () => {
    try {
      this.referralTrackingCode();
      const profile = localStorage.getItem("profile");
      const token = localStorage.getItem("token");
      console.log("Retrieved token: ", token);
      if (profile && token) {
        this.jwtToken = token;
        this.api.defaults.headers.common["x-access-token"] = token;
        this.profile = JSON.parse(profile);
        this.isLoggedIn = true;
        this.refreshTokenAndProfile();
      }
    } catch (err) {
      console.log(err);
    }
  };

  referralTrackingCode = async () => {
    let referral = new URLSearchParams(window.location.search).get("referral");
    if (referral) {
      this.setReferral(referral);
    } else {
      this.initReferral();
    }
  };

  setProfile = async (profile) => {
    this.profile = profile;
    localStorage.setItem("profile", JSON.stringify(profile));
  };

  setReferral = async (referral) => {
    this.referral = referral;
    localStorage.setItem("referral", JSON.stringify(referral));
  };

  initReferral = async () => {
    const referral = localStorage.getItem("referral");
    this.referral = referral;
  };

  loginWithDataTokenAndProfile = async (data) => {
    this.setToken(data.token);
    console.log("Login data token: ", data.token);
    this.setProfile(data.profile);
    this.isLoggedIn = true;
  };

  refreshTokenAndProfile = async () => {
    try {
      let data = await this.api.post("/user/refresh/profile").then(({ data }) => data);
      if (data) {
        this.setProfile(data.profile);
      }
    } catch (err) {
      console.log(err);
      this.handleLogout();
    }
  };

  setToken = async (token) => {
    this.jwtToken = token;
    this.api.defaults.headers.common["x-access-token"] = token;
    localStorage.setItem("token", token);
    console.log("Token set in store: ", this.jwtToken);
  };

  setProfile = async (profile) => {
    this.profile = profile;
    localStorage.setItem("profile", JSON.stringify(profile));
  };

  handleLogout = () => {
    this.isLoggedIn = false;
    this.profile = {};
    this.api.defaults.headers.common["x-access-token"] = "";
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
  };

  @computed get filteredTools() {
    const keyword = this.toolsKeyword.toLowerCase();
    return this.tools.filter((tool) => {
      if (!tool) return false;
      const searchableFields = ["_id", "title", "description", "category", "keywords"];
      return searchableFields.some((field) => tool[field]?.toLowerCase().includes(keyword));
    });
  }

  @computed get allTools() {
    return [...TOOLS, ...this.filteredTools];
  }

  getToolByUrl = (url) => {
    return this.allTools.find((tool) => tool.to === url);
  };

  onChangeToolsKeyword = async (e) => {
    this.toolsKeyword = e.target.value;
    await this.fetchTools();
  };

  fetchTools = async () => {
    if (!this.toolsKeyword) {
      this.tools = TOOLS;
      return;
    }
    this.loadingTools = true;
    try {
      const response = await fetch(`https://app.topmarks.ai/api/user/tools/search?keyword=${this.toolsKeyword}`, {
        headers: {
          Authorization: `Bearer ${this.jwtToken}`,
        },
      });
      const data = await response.json();
      runInAction(() => {
        this.tools = [...TOOLS, ...data];
      });
    } catch (error) {
      console.error("Error fetching tools:", error);
    } finally {
      runInAction(() => {
        this.loadingTools = false;
      });
    }
  };

  copyToClipboard = (output) => {
    if (output instanceof Array) {
      output = output.join("\n");
    }
    if (!navigator.clipboard) {
      let textarea = document.getElementById("copy-textarea");
      this.copyToClipboardText = `${output}`;
      textarea.focus();
      textarea.select();
      document.execCommand("copy");
      return;
    }
    navigator.clipboard.writeText(output).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  reportToFeedback = (output) => {
    this.redirect = "/my-profile/feedback";
    this.feedback = `${output}`;
    setTimeout(() => {
      this.redirect = "";
    }, 50);
  };

  checkPrompt = ({ value, attr }) => {
    if (filterBadWords.isProfane(value)) {
      throw {
        success: false,
        attr,
        value: value.trim(),
        message: "Unsafe content detected, please try different language",
      };
    }
    if (value) {
      return {
        success: true,
        attr,
        value: value.trim(),
      };
    }
  };

  checkOutput = (output) => {
    if (output) {
      return output.trim();
    }
    return "";
  };

  updateCredits = async (data) => {
    try {
      if (data.hasOwnProperty("data")) {
        if (data.data.hasOwnProperty("credits")) {
          this.profile.credits = data.data.credits;
        }
        if (data.data.hasOwnProperty("creditsUsed")) {
          this.profile.creditsUsed = data.data.creditsUsed;
        }
      } else {
        if (data.hasOwnProperty("credits")) {
          this.profile.credits = data.credits;
        }
        if (data.hasOwnProperty("creditsUsed")) {
          this.profile.creditsUsed = data.creditsUsed;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
}

export default AppStore;
