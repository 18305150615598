import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ocrenglish from "./ocrenglish.png";
import nineplus from "./aqaenglish.png";
import tenplus from "./cambridgeenglish.png";
import eightplus from "./edexcelenglish.png";
import edexengl from "./edexengl.png";
import elevenplus from "./eduqasenglish.png";
import wjeceng from "./wjeceng.png";
import cceaenglish from "./cceaenglish.png";
import edexihist from "./edexihist.png";
import sixplus from "./edexcelhistory.png";
import aqahistory from "./aqahistory.png";
import cambridgehistory from "./cambridgehistory.png";
import ocrhistgcse from "./ocrhistgcse.png";
import eduqashistory from "./eduqashistory.png";
import ocrrs from "./ocrrs.png";
import edexcelrs from "./edexcelrs.png";
import aqars from "./aqars.png";
import ocreco from "./ocreco.png";
import aqadrama from "./aqadrama.png";
import edexgeog from "./edexgeog.png";
import aqageog from "./aqageog.png";
import edexbus from "./edexbus.png";
import edexgeogi from "./edexgeogi.png";

const GCSESection = ({ permissions }) => {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem("gcseToolOrder");
    if (savedOrder) {
      setTools(JSON.parse(savedOrder));
    } else {
      const defaultTools = [
        { id: "ocr-english", to: "/ocr-english", img: ocrenglish, alt: "OCR English" },
        { id: "aqa-english", to: "/aqa-english", img: nineplus, alt: "AQA English" },
        { id: "cantab-english", to: "/cantab-english", img: tenplus, alt: "Cambridge English" },
        { id: "edexcel-english", to: "/edexcel-english", img: eightplus, alt: "Edexcel English" },
        { id: "english-edexcel", to: "/english-edexcel", img: edexengl, alt: "English Edexcel" },
        { id: "eduqas-english", to: "/eduqas-english", img: elevenplus, alt: "Eduqas English" },
        { id: "wjec-english", to: "/wjec-english", img: wjeceng, alt: "WJEC English" },
        { id: "english-ccea", to: "/english-ccea", img: cceaenglish, alt: "CCEA English" },
        { id: "edexcel-ihistory", to: "/edexcel-ihistory", img: edexihist, alt: "Edexcel International History" },
        { id: "edexcel-history", to: "/edexcel-history", img: sixplus, alt: "Edexcel History" },
        { id: "aqa-history", to: "/aqa-history", img: aqahistory, alt: "AQA History" },
        { id: "cantab-history", to: "/cantab-history", img: cambridgehistory, alt: "Cambridge History" },
        { id: "ocr-history", to: "/ocr-history", img: ocrhistgcse, alt: "OCR History" },
        { id: "edu-history", to: "/edu-history", img: eduqashistory, alt: "Eduqas History" },
        { id: "ocr-rs", to: "/ocr-rs", img: ocrrs, alt: "OCR Religious Studies" },
        { id: "edexcel-rs", to: "/edexcel-rs", img: edexcelrs, alt: "Edexcel Religious Studies" },
        { id: "aqa-rs", to: "/aqa-rs", img: aqars, alt: "AQA Religious Studies" },
        { id: "ocr-economics", to: "/ocr-economics", img: ocreco, alt: "OCR Economics" },
        { id: "aqa-drama", to: "/aqa-drama", img: aqadrama, alt: "AQA Drama" },
        { id: "edex-geog", to: "/edex-geog", img: edexgeog, alt: "Edexcel Geography" },
        { id: "edex-geogi", to: "/edex-geogi", img: edexgeogi, alt: "IEdexcel Geography" },
        { id: "aqa-geog", to: "/aqa-geog", img: aqageog, alt: "AQA Geography" },
        { id: "edex-bus", to: "/edex-bus", img: edexbus, alt: "Edexcel Business" },
      ];
      setTools(defaultTools);
      localStorage.setItem("gcseToolOrder", JSON.stringify(defaultTools));
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newTools = Array.from(tools);
    const [reorderedItem] = newTools.splice(result.source.index, 1);
    newTools.splice(result.destination.index, 0, reorderedItem);

    setTools(newTools);
    localStorage.setItem("gcseToolOrder", JSON.stringify(newTools));
  };

  return (
    <>
      <div className="title-container">
        <h2 className="title-text title-bubble">GCSE & IGCSE Marking Tools</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="gcse-tools" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 custom-grid"
            >
              {tools.map((tool, index) => (
                <Draggable key={tool.id} draggableId={tool.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex flex-col items-center"
                    >
                      <Link to={tool.to} className="flex flex-col items-center w-full">
                        <img className="tool-img w-full mb-4" src={tool.img} alt={tool.alt} />
                        <button className="tool-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg w-3/4 flex items-center justify-center">
                          Browse
                        </button>
                      </Link>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default GCSESection;
