import {
	AcademicCapIcon,
  } from '@heroicons/react/solid';
  import FileUploadButton from '../Components/FileUploadButton';
  
  const obj = {
	title: "Turn Handwritten Scripts into Text (NOTE: You must add context for this tool to function correctly)!",
	desc: "Use this tool to convert PDFs of handwritten scripts into fully editable text (that we can then mark!)",
	category: "",
	Icon: AcademicCapIcon,
	permissions: ['user'],
  
	fromColor: "blue-400",
	toColor: "blue-600",
  
	to: "/ai/writing/handwriting",
	api: "/ai/writing/handwriting",
  
	output: {
	  title: "Turn Handwritten Scripts into Text",
	  desc: "Please wait as we decode the handwriting!",
	},
  
	prompts: [{
	  title: "Use this tool to convert PDFs of handwritten scripts into fully editable text (that we can then mark!)",
	  desc: "Upload the essay response in PDF format. Make sure the images are clear, and that the file is no larger than 5 pages",
	  prompts: [
		{ 
			title: "Context", 
			attr: "essay_title",  
			value: "", 
			placeholder: "e.g. The student has written an essay entitled 'How far can Macbeth be considered an intrinsically violent individual?", 
			label: "Essay Title",
			type: "textarea",
			maxLength: 150,
			required: true,
			error: "",
			example: "The student has written an essay entitled 'How far can Macbeth be considered an intrinsically violent individual?'"
		},
		{
			title: "Upload a PDF.",
			name: "essays", // This should match the multer configuration on the server
			attr: "essays", // This should match the multer configuration on the server
					value: "",
			label: "Upload your PDF file",
			type: "file",
			required: true,
			error: "",
		  }
		  
	  ],
	  example: {
		output: "Percentage  \n\nEND OF RESPONSE.",
	  }
	}]
  };
  
  export default obj;
  