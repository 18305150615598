import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import MainBody from "./Components/Body";
import { Grid } from "./Dashboard";

const SearchTool = ({ title, desc, to, fromColor, toColor, category, color }) => (
  <Link to={to || "/"} className="flex relative">
    <div
      className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105 hover:text-black`}
    >
      <div className="p-4">
        <div className={`uppercase tracking-wide text-sm text-${color}-500 font-semibold leading-none`}>{category}</div>
        <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black">{title}</div>
        <p className="mt-1 pr-1 text-sm text-gray-500">{desc}</p>
      </div>
    </div>
  </Link>
);

@inject("store")
@observer
class Search extends Component {
  render() {
    const { filteredTools, loadingTools } = this.props.store;
    console.log("Filtered Tools:", filteredTools);
    console.log("Loading Tools:", loadingTools);

    if (loadingTools) {
      return <div>Loading...</div>;
    }

    return (
      <>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
          <Grid>
            {filteredTools.map((tool, index) => (
              <SearchTool
                key={index}
                title={tool.title}
                to={`/writing/${tool._id}`}
                desc={tool.description}
                fromColor={tool.fromColor}
                toColor={tool.toColor}
                category={tool.category}
                color={tool.color}
              />
            ))}
          </Grid>
        </MainBody>
      </>
    );
  }
}

export default Search;
