import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ks2 from "./ks2.png";
import englangks3 from "./ks3englang.png";

const KS3Section = ({ permissions }) => {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem("ks3ToolOrder");
    if (savedOrder) {
      setTools(JSON.parse(savedOrder));
    } else {
      const defaultTools = [
        { id: "english-ks2", to: "/english-ks2", img: ks2, alt: "KS2 English" },
        { id: "ks3-language", to: "/ks3-language", img: englangks3, alt: "KS3 English Language" },
      ];
      setTools(defaultTools);
      localStorage.setItem("ks3ToolOrder", JSON.stringify(defaultTools));
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newTools = Array.from(tools);
    const [reorderedItem] = newTools.splice(result.source.index, 1);
    newTools.splice(result.destination.index, 0, reorderedItem);

    setTools(newTools);
    localStorage.setItem("ks3ToolOrder", JSON.stringify(newTools));
  };

  return (
    <>
      <div className="title-container">
        <h2 className="title-text ks3-title-bubble">KS2 & KS3 Marking Tools</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="ks3-tools" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 custom-grid"
            >
              {tools.map((tool, index) => (
                <Draggable key={tool.id} draggableId={tool.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex flex-col items-center"
                    >
                      <Link to={tool.to} className="flex flex-col items-center w-full">
                        <img className="tool-img w-full mb-4" src={tool.img} alt={tool.alt} />
                        <button className="tool-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg w-3/4 flex items-center justify-center">
                          Browse
                        </button>
                      </Link>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default KS3Section;
