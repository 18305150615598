const dev = {
  baseURL: "http://localhost:3080/api/",
  landingPageUrl: "http://localhost:3080",
  stripe: {
    gbp: {
      free: "price_1MP5pmC4gpAfet0jSlSAxYOK",
      entry: "price_1MP3iZC4gpAfet0j167o41rX",
      pro: "price_1MP5kzC4gpAfet0j9zefq8Yv",
    },
    usd: {
      free: "price_1MP5pmC4gpAfet0jSlSAxYUS",
      entry: "price_1Pf4tGC4gpAfet0jijRtWIvd",
      pro: "price_1Pf4xIC4gpAfet0j5ln8KnxT",
    },
  },
};

const prod = {
  baseURL: "/api/",
  landingPageUrl: "https://app.topmarks.ai",
  stripe: {
    gbp: {
      free: "price_1MPoKTC4gpAfet0jJMUsoTjh",
      entry: "price_1MPoMXC4gpAfet0j4sTl1jU5",
      pro: "price_1MPoNmC4gpAfet0jFRBiUMTo",
    },
    usd: {
      free: "price_1MPoKTC4gpAfet0jJMUsoTjh",
      entry: "price_1NIVnAC4gpAfet0j7AVKXp6p",
      pro: "price_1NIVs6C4gpAfet0jGXqrnbSM",
    },
  },
};

const config = process.env.NODE_ENV === "development" ? dev : prod;

export default config;
