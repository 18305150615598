import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import aqaenglitalevel from "./aqaenglitalevel.png";
import ocrenglitalevel from "./ocrenglitalevel.png";
import eduqasalevel from "./eduqasalevel.png";
import edexenglish from "./edexenglish.png";
import aqaphilo from "./aqaphilo.png";
import sevenplus from "./edexcelecon.png";
import geogedex from "./geogedex.png";
import geogocr from "./geogocr.png";
import aedexcelhist from "./aedexcelhist.png";
import ciehist from "./ciehist.png";
import ocrhist from "./ocrhist.png";
import psych from "./psych.png";
import psychaqa from "./psychaqa.png";
import ciesoc from "./ciesoc.png";

import psychocr from "./psychocr.png";
import socaqa from "./socaqa.png";
import sococr from "./sococr.png";
import poledex from "./poledex.png";
import busedex from "./busedex.png";
import busaqa from "./busaqa.png";
import cieenglish from "./cieenglang.png"; // <-- New import for CIE English

const ALevelSection = ({ permissions }) => {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem("toolOrder");
    if (savedOrder) {
      setTools(JSON.parse(savedOrder));
    } else {
      const defaultTools = [
        { id: "english-aqa", to: "/english-aqa", img: aqaenglitalevel, alt: "AQA English" },
        { id: "english-ocr", to: "/english-ocr", img: ocrenglitalevel, alt: "OCR English" },
        { id: "english-eduqas", to: "/english-eduqas", img: eduqasalevel, alt: "Eduqas English" },
        { id: "edex-english", to: "/edex-english", img: edexenglish, alt: "Edexcel English" },
        { id: "cie-english", to: "/cie-english", img: cieenglish, alt: "CIE English" }, // <-- New CIE English entry
        { id: "aqa-philosophy", to: "/aqa-philosophy", img: aqaphilo, alt: "AQA Philosophy" },
        { id: "edexcel-economics", to: "/edexcel-economics", img: sevenplus, alt: "Edexcel Economics" },
        { id: "geog-edex", to: "/geog-edex", img: geogedex, alt: "Edexcel Geography" },
        { id: "geog-ocr", to: "/geog-ocr", img: geogocr, alt: "OCR Geography" },
        { id: "history-edexcel", to: "/history-edexcel", img: aedexcelhist, alt: "Edexcel History" },
        { id: "cie-hist", to: "/cie-hist", img: ciehist, alt: "CIE History" },
        { id: "history-ocr", to: "/history-ocr", img: ocrhist, alt: "OCR History" },
        { id: "psych-edex", to: "/psych-edex", img: psych, alt: "Edexcel Psychology" },
        { id: "psych-aqa", to: "/psych-aqa", img: psychaqa, alt: "AQA Psychology" },
        { id: "psych-ocr", to: "/psych-ocr", img: psychocr, alt: "OCR Psychology" },
        { id: "soc-aqa", to: "/soc-aqa", img: socaqa, alt: "AQA Sociology" },
        { id: "soc-ocr", to: "/soc-ocr", img: sococr, alt: "OCR Sociology" },
        { id: "cie-soc", to: "/cie-soc", img: ciesoc, alt: "CIE Sociology" },
        { id: "pol-edex", to: "/pol-edex", img: poledex, alt: "Edexcel Politics" },
        { id: "bus-edex", to: "/bus-edex", img: busedex, alt: "Edexcel Business" },
        { id: "aqa-bus", to: "/aqa-bus", img: busaqa, alt: "AQA Business" },
      ];
      setTools(defaultTools);
      localStorage.setItem("toolOrder", JSON.stringify(defaultTools));
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newTools = Array.from(tools);
    const [reorderedItem] = newTools.splice(result.source.index, 1);
    newTools.splice(result.destination.index, 0, reorderedItem);

    setTools(newTools);
    localStorage.setItem("toolOrder", JSON.stringify(newTools));
  };

  return (
    <>
      <div className="title-container">
        <h2 className="title-text second-title-bubble">A Level Marking Tools</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="tools" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 custom-grid"
            >
              {tools.map((tool, index) => (
                <Draggable key={tool.id} draggableId={tool.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex flex-col items-center"
                    >
                      <Link to={tool.to} className="flex flex-col items-center w-full">
                        <img className="tool-img w-full mb-4" src={tool.img} alt={tool.alt} />
                        <button className="tool-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg w-3/4 flex items-center justify-center">
                          Browse
                        </button>
                      </Link>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ALevelSection;
