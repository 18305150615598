import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ielts from "./ielts.png";
import hkdse from "./hkdse.png";
import oet from "./oet.png";

const IELTSSection = ({ permissions }) => {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem("ieltsToolOrder");
    if (savedOrder) {
      setTools(JSON.parse(savedOrder));
    } else {
      const defaultTools = [
        { id: "ielts", to: "/ielts", img: ielts, alt: "IELTS" },
        { id: "hkdse", to: "/hkdse", img: hkdse, alt: "HKDSE" },
        { id: "oet", to: "/oet", img: oet, alt: "OET" },
      ];
      setTools(defaultTools);
      localStorage.setItem("ieltsToolOrder", JSON.stringify(defaultTools));
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newTools = Array.from(tools);
    const [reorderedItem] = newTools.splice(result.source.index, 1);
    newTools.splice(result.destination.index, 0, reorderedItem);

    setTools(newTools);
    localStorage.setItem("ieltsToolOrder", JSON.stringify(newTools));
  };

  return (
    <>
      <div className="title-container">
        <h2 className="title-text ks3-title-bubble">IELTS & HKDSE & OET</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="ielts-tools" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 custom-grid"
            >
              {tools.map((tool, index) => (
                <Draggable key={tool.id} draggableId={tool.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex flex-col items-center"
                    >
                      <Link to={tool.to} className="flex flex-col items-center w-full">
                        <img className="tool-img w-full mb-4" src={tool.img} alt={tool.alt} />
                        <button className="tool-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg w-3/4 flex items-center justify-center">
                          Browse
                        </button>
                      </Link>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default IELTSSection;
