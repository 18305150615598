import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { DatabaseIcon, LinkIcon, ArrowDownIcon, UsersIcon } from "@heroicons/react/outline";
import TwitterIcon from "../media/twitter";
import FacebookIcon from "../media/facebook";

@inject("store")
@observer
class Referral extends Component {
  value = `${window.store.landingPageUrl}/signup?referral=${this.props.store.profile.referralId}`;

  render() {
    const { referralCredits, freeReferrals, paidReferrals } = this.props.store.profile;

    return (
      <>
        <div className="align-bottom bg-white md:rounded-md text-left overflow-hidden shadow-xl transform transition-all sm:align-middle transition shadow-md hover:shadow-2xl focus:shadow-2xl mb-4 md:mb-8">
          <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h2 className="text-4xl">Referral Program</h2>
            <h2 className="text-gray-500 mb-8">Earn credits by referring people to Top Marks AI!</h2>

            <div className="flex items-center mb-4">
              <div className="flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-yellow-200 sm:mx-0 sm:h-10 sm:w-10">
                <DatabaseIcon className="h-3 w-3 md:h-6 md:w-6 text-yellow-700" aria-hidden="true" />
              </div>
              <div className="mt-0 ml-4 text-left">
                <div className="text-lg leading-6 font-medium text-gray-900">Total Referral Credits Earned: {referralCredits || 0}</div>
                <p className="text-sm text-gray-500">
                  Free Plan Referrals: {freeReferrals || 0} | Paid Plan Referrals: {paidReferrals || 0}
                </p>
              </div>
            </div>

            <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-4" role="alert">
              <p className="font-bold">How to earn referral credits:</p>
              <ul className="list-disc list-inside">
                <li>Earn 10 credits when someone signs up for a free plan using your link</li>
                <li>Earn 100 credits when someone signs up for a paid plan using your link</li>
                <li>If a free plan user upgrades to a paid plan, you'll earn an additional 90 credits</li>
              </ul>
            </div>

            <div className="flex items-center">
              <div className="flex-shrink-0 inline-flex items-center justify-center md:h-12 md:w-12 h-6 w-6 rounded-full bg-green-200 sm:mx-0 sm:h-10 sm:w-10">
                <UsersIcon className="h-3 w-3 md:h-6 md:w-6 text-green-700" aria-hidden="true" />
              </div>
              <div className="mt-0 ml-4 text-left">
                <div className="text-lg leading-6 font-medium text-gray-900">Share your referral link</div>
                <p className="text-sm text-gray-500">Use the link below to share on social media and earn credits!</p>
              </div>
            </div>

            <div className="flex mt-4 mb-4">
              <div className="mt-1 flex rounded-md flex-1">
                <input
                  id="referral"
                  type="text"
                  value={this.value}
                  className="focus:ring-indigo-500 border focus:border-indigo-500 flex-1 rounded border-gray-300 py-3 px-6 flex-1 bg-gray-100 hover:bg-gray-200"
                  placeholder="www.example.com"
                  onChange={() => {}}
                  onClick={() => {
                    document.getElementById("referral").select();
                    this.props.store.copyToClipboard(this.value);
                  }}
                  readOnly
                />
              </div>
            </div>

            <div className="grid grid-cols-12 gap-4">
              <div className="flex items-center" onClick={() => this.props.store.copyToClipboard(this.value)}>
                <div className="cursor-pointer bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800 flex-shrink-0 inline-flex items-center justify-center md:h-16 md:w-16 h-6 w-6 rounded-full sm:mx-0 sm:h-12 sm:w-12">
                  <LinkIcon className="h-3 w-3 md:h-8 md:w-8" aria-hidden="true" />
                </div>
              </div>

              <a
                rel="noopener noreferrer"
                href={`https://twitter.com/intent/tweet?text=Check out Top Marks AI and give it a try with the following link: ${this.value}`}
                className="flex items-center"
                target="_blank"
              >
                <div className="cursor-pointer bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800 flex-shrink-0 inline-flex items-center justify-center md:h-16 md:w-16 h-6 w-6 rounded-full sm:mx-0 sm:h-12 sm:w-12">
                  <TwitterIcon className="h-3 w-3 md:h-8 md:w-8" aria-hidden="true" />
                </div>
              </a>

              <a
                rel="noopener noreferrer"
                href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(this.value)}`}
                className="flex items-center"
                target="_blank"
              >
                <div className="cursor-pointer bg-gray-200 text-gray-700 hover:bg-gray-300 hover:text-gray-800 flex-shrink-0 inline-flex items-center justify-center md:h-16 md:w-16 h-6 w-6 rounded-full sm:mx-0 sm:h-12 sm:w-12">
                  <FacebookIcon className="h-3 w-3 md:h-8 md:w-8" aria-hidden="true" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Referral;
