import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./Status.css";

const Status = ({ priceId, currentPeriodEnd, isCancelled }) => {
  const [status, setStatus] = useState("");
  const [subscriptionEndDate, setSubscriptionEndDate] = useState("");
  const [subscriptionEndTime, setSubscriptionEndTime] = useState("");

  useEffect(() => {
    if (isCancelled) {
      setStatus("Subscription cancelled");
      setSubscriptionEndDate("");
      setSubscriptionEndTime("");
      return;
    }

    if (!currentPeriodEnd) {
      setSubscriptionEndDate("N/A");
      setSubscriptionEndTime("N/A");
    } else {
      const subscriptionEndValue = new Date(currentPeriodEnd);
      if (subscriptionEndValue.toString() === "Invalid Date") {
        setSubscriptionEndDate("N/A");
        setSubscriptionEndTime("N/A");
      } else {
        setSubscriptionEndDate(subscriptionEndValue.toLocaleDateString());
        setSubscriptionEndTime(subscriptionEndValue.toLocaleTimeString());
      }
    }

    // Updated switch statement to handle both custom labels and Stripe price IDs
    switch (priceId) {
      case "STRIPEPRODUCTFREE":
      case "price_1MP5pmC4gpAfet0jSlSAxYOK": // Assuming this is the Free tier Stripe ID
        setStatus("Free");
        break;
      case "STRIPEPRODUCTENTRY":
      case "price_1MP3iZC4gpAfet0j167o41rX": // Replace with actual Entry tier Stripe ID
        setStatus("Entry (£20 per month)");
        break;
      case "STRIPEPRODUCTPRO":
      case "price_1MP5kzC4gpAfet0j9zefq8Yv": // Replace with actual Pro tier Stripe ID
        setStatus("Pro (£40 per month)");
        break;
      default:
        setStatus("Unknown");
        break;
    }
  }, [priceId, currentPeriodEnd, isCancelled]);

  return (
    <div className="status-container">
      <div className="status-bubble">
        <p className="status-text">Current Pricing Tier: {status}</p>
        {isCancelled ? (
          <p className="status-text">Subscription Ends On: Subscription cancelled</p>
        ) : (
          <p className="status-text">
            Subscription Ends On: {subscriptionEndDate} at {subscriptionEndTime}
          </p>
        )}
      </div>
    </div>
  );
};

export default observer(Status);
