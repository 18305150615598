import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import nineplus from "./aqaenglish.png";
import aqahistory from "./aqahistory.png";
import aqars from "./aqars.png";
import aqadrama from "./aqadrama.png";
import aqageog from "./aqageog.png";
import aqaenglitalevel from "./aqaenglitalevel.png";
import aqaphilo from "./aqaphilo.png";
import psychaqa from "./psychaqa.png";
import socaqa from "./socaqa.png";
import busaqa from "./busaqa.png";

const AQASection = ({ permissions }) => {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem("aqaToolOrder");
    if (savedOrder) {
      setTools(JSON.parse(savedOrder));
    } else {
      const defaultTools = [
        { id: "aqa-english-gcse", to: "/aqa-english", img: nineplus, alt: "AQA English GCSE" },
        { id: "aqa-history-gcse", to: "/aqa-history", img: aqahistory, alt: "AQA History GCSE" },
        { id: "aqa-rs-gcse", to: "/aqa-rs", img: aqars, alt: "AQA Religious Studies GCSE" },
        { id: "aqa-drama-gcse", to: "/aqa-drama", img: aqadrama, alt: "AQA Drama GCSE" },
        { id: "aqa-geog-gcse", to: "/aqa-geog", img: aqageog, alt: "AQA Geography GCSE" },
        { id: "english-aqa-alevel", to: "/english-aqa", img: aqaenglitalevel, alt: "AQA English A-Level" },
        { id: "aqa-philosophy-alevel", to: "/aqa-philosophy", img: aqaphilo, alt: "AQA Philosophy A-Level" },
        { id: "psych-aqa-alevel", to: "/psych-aqa", img: psychaqa, alt: "AQA Psychology A-Level" },
        { id: "soc-aqa-alevel", to: "/soc-aqa", img: socaqa, alt: "AQA Sociology A-Level" },
        { id: "aqa-bus-alevel", to: "/aqa-bus", img: busaqa, alt: "AQA Business A-Level" },
      ];
      setTools(defaultTools);
      localStorage.setItem("aqaToolOrder", JSON.stringify(defaultTools));
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newTools = Array.from(tools);
    const [reorderedItem] = newTools.splice(result.source.index, 1);
    newTools.splice(result.destination.index, 0, reorderedItem);

    setTools(newTools);
    localStorage.setItem("aqaToolOrder", JSON.stringify(newTools));
  };

  return (
    <>
      <div className="title-container">
        <h2 className="title-text title-bubble">AQA Tools</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="aqa-tools" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 custom-grid"
            >
              {tools.map((tool, index) => (
                <Draggable key={tool.id} draggableId={tool.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex flex-col items-center"
                    >
                      <Link to={tool.to} className="flex flex-col items-center w-full">
                        <img className="tool-img w-full mb-4" src={tool.img} alt={tool.alt} />
                        <button className="tool-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg w-3/4 flex items-center justify-center">
                          Browse
                        </button>
                      </Link>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default AQASection;
