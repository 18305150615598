import React from "react";
import { useLocation } from "react-router-dom";

const NavLink = ({ href, children }) => (
  <a
    href={href}
    className="mx-2 mb-2 bg-green-500 hover:bg-green-600 text-white rounded-full px-3 sm:px-4 md:px-5 py-2 text-center flex justify-center items-center whitespace-nowrap"
  >
    {children}
  </a>
);

const SpecNavLinks = () => {
  const location = useLocation();

  // List of paths where we don't want to show the navigation links
  const excludedPaths = ["/my-profile", "/my-profile/pricing", "/my-profile/referral"];

  // Check if the current path is in the excluded list
  const isExcludedPath = excludedPaths.some((path) => location.pathname === path || location.pathname.endsWith(path));

  // If we're on an excluded path, don't render anything
  if (isExcludedPath) {
    return null;
  }

  // Extract the base tool name from the current path
  const pathParts = location.pathname.split("/");
  const basePath = pathParts.slice(0, -1).join("/");
  const currentTool = pathParts[pathParts.length - 1].replace(/hand|batch|word|multi|zip$/, "");

  const linkTypes = [
    { suffix: "", text: "Single Essay Marking: Typed" },
    { suffix: "hand", text: "Single Essay Marking: Handwritten Script" },
    { suffix: "batch", text: "Batch Marking: Typed Essays" },
    { suffix: "word", text: "Batch Marking: Typed Essays in Word" },
    { suffix: "multi", text: "Batch Marking: Handwritten Scripts" },
    { suffix: "zip", text: "Batch Marking: Handwritten Scripts - Barcoded Single PDF" },
  ];

  const links = linkTypes.map((type) => ({
    path: `${basePath}/${currentTool}${type.suffix}`,
    text: type.text,
  }));

  return (
    <div className="flex flex-wrap justify-center items-center">
      {links
        .filter((link) => link.path !== location.pathname)
        .map((link, index) => (
          <NavLink key={index} href={link.path}>
            {link.text}
          </NavLink>
        ))}
    </div>
  );
};

export default SpecNavLinks;
