import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { computed, observable, makeObservable } from "mobx";
import Header from "../Components/Header";
import { CheckIcon, UserCircleIcon, ReplyIcon, ChevronLeftIcon, XCircleIcon, CurrencyPoundIcon, GiftIcon } from "@heroicons/react/outline";
import MainBody from "../Components/Body";
import Referral from "./Referral";
import Feedback from "./Feedback";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import Pricing from "../Pricing";
import Status from "./Status";

@inject("store")
@observer
class Body extends Component {
  @observable showCancelModal = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get headerMessage() {
    const { status, plan, cancel_at_period_end } = this.props.store.profile;
    if (status === "trialing") return "7 Day Trial";
    if (status === "active") return cancel_at_period_end ? "Cancels at end of period" : `${plan} Plan`;
    if (status === "incomplete") return `${plan} Plan Restarted`;
    return "Expired";
  }

  @computed get ifNotActive() {
    const { cancel_at_period_end, status } = this.props.store.profile;
    if (cancel_at_period_end) return "Canceling";
    if (status === "trialing") return "Trialing";
    return false;
  }

  @computed get fromColor() {
    const { status, cancel_at_period_end } = this.props.store.profile;
    if (status === "trialing") return "gray-400";
    if (status === "active") return cancel_at_period_end ? "yellow-500" : "green-500";
    if (status === "incomplete") return "yellow-600";
    return "red-500";
  }

  @computed get currentPeriodEnd() {
    const { current_period_end } = this.props.store.profile;
    if (current_period_end) {
      const daysDifference = Math.round((new Date(current_period_end).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
      return daysDifference < 0 ? 0 : daysDifference;
    }
    return 0;
  }

  @observable plan = {
    plan: "",
    cancel_at_period_end: false,
    status: "active",
  };

  componentDidMount() {
    this.props.store.refreshTokenAndProfile();
    this.init();
  }

  init = async () => {
    const res = await this.props.store.api.post("/user/stripe/plan");
    this.plan = res.data;
  };

  onBack = () => {
    this.props.history.push("/my-profile");
  };

  onCancelClick = (e) => {
    e.preventDefault();
    this.showCancelModal = true;
  };

  handleConfirmCancel = () => {
    this.showCancelModal = false;
    document.getElementById("cancel-form").submit();
  };

  handleCloseModal = () => {
    this.showCancelModal = false;
  };

  render() {
    return (
      <>
        <Header
          title={this.props.store.profile.email}
          desc={`${this.props.store.profile.fname} ${this.props.store.profile.lname}`}
          category="Your Profile"
          Icon={UserCircleIcon}
          fromColor={this.fromColor}
          options={
            this.props.location.pathname !== "/my-profile"
              ? [{ title: "Back to Profile", Icon: ChevronLeftIcon, onClick: this.onBack }]
              : null
          }
        >
          <Route exact path="/my-profile">
            <Helmet>
              <title>{`My Profile - TopMarks.Ai`}</title>
            </Helmet>
          </Route>
        </Header>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
          <div className="container mx-auto p-5">
            <Status
              priceId={this.plan.priceId}
              currentPeriodEnd={this.plan.current_period_end}
              isCancelled={this.plan.cancel_at_period_end}
            />
          </div>
          <Switch>
            <Route exact path="/my-profile/pricing">
              <Pricing />
            </Route>
            <Route exact path="/my-profile/referral">
              <Referral />
            </Route>
            <Route exact path="/my-profile/feedback">
              <Feedback />
            </Route>
            <Route>
              <Grid>
                <Tool
                  Icon={CurrencyPoundIcon}
                  title={"Pricing Plans"}
                  api={this.props.store.api}
                  desc={"Upgrade, downgrade or cancel anytime."}
                  to={"/my-profile/pricing"}
                  fromColor="green-400"
                />

                <Tool
                  Icon={GiftIcon}
                  title={"Referral Program"}
                  desc={"Earn credits by referring friends!"}
                  to={"/my-profile/referral"}
                  fromColor="yellow-400"
                />

                {this.plan.status === "trialing" && (
                  <ToolForm
                    Icon={CheckIcon}
                    title={`Active Subscription`}
                    desc={`${
                      this.plan.plan === "Entry" ? "$30" : this.plan.plan === "Pro" ? "$90" : ""
                    } billing immediately. Ends trial and starts billing plan.`}
                    to={this.props.store.baseURL + "/user/stripe/activate"}
                    api={this.props.store.api}
                    fromColor="purple-500"
                    toColor="indigo-600"
                  />
                )}

                {this.headerMessage !== "Expired" && (
                  <>
                    {!this.plan.cancel_at_period_end ? (
                      <ToolForm
                        Icon={XCircleIcon}
                        title={"Cancel Subscription"}
                        api={this.props.store.api}
                        desc={`Schedule your subscription to cancel at the end of the billing period. You will retain access to your credits until then.`}
                        to={this.props.store.baseURL + "user/stripe/cancel"}
                        fromColor="red-500"
                        toColor="red-600"
                        onClick={this.onCancelClick}
                      />
                    ) : (
                      <ToolForm
                        Icon={CheckIcon}
                        title={"Reactivate Subscription"}
                        api={this.props.store.api}
                        desc={"Reactivate your subscription and continue your current plan."}
                        to={this.props.store.baseURL + "user/stripe/resubscribe"}
                        fromColor="green-500"
                        toColor="green-600"
                      />
                    )}
                  </>
                )}

                <ToolDiv
                  Icon={ReplyIcon}
                  title={"Log Out"}
                  desc={"Sign out of your account"}
                  onClick={this.props.store.handleLogout}
                  fromColor="gray-400"
                  toColor="gray-400"
                />
              </Grid>
            </Route>
          </Switch>

          {/* Confirmation Modal */}
          {this.showCancelModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4">Confirm Cancellation</h2>
                <p>
                  Your subscription will be canceled at the end of your current billing period. You will retain access to your credits until
                  then, but your credits will be reset to 0 when your subscription ends. Are you sure you want to proceed?
                </p>
                <div className="flex justify-end mt-4">
                  <button className="mr-4 px-4 py-2 bg-gray-300 text-gray-800 rounded" onClick={this.handleCloseModal}>
                    No, Keep Subscription
                  </button>
                  <button className="px-4 py-2 bg-red-600 text-white rounded" onClick={this.handleConfirmCancel}>
                    Yes, Cancel Subscription
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Form to handle cancellation */}
          <form id="cancel-form" action={this.props.store.baseURL + "user/stripe/cancel"} method="POST">
            <input type="hidden" name="token" value={this.props.store.api.defaults.headers.common["x-access-token"]} />
          </form>
        </MainBody>
      </>
    );
  }
}

const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3">{children}</div>;

const ToolDiv = ({ Icon, title, desc, fromColor, toColor, onClick }) => (
  <div className="flex relative" onClick={onClick}>
    <div
      className={`absolute inset-0 bg-gradient-to-r from-${fromColor || "green-400"} to-${
        toColor || "blue-500"
      } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl`}
    ></div>
    <div
      className={`flex-1 bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border-t-2 border- hover:border-${
        fromColor || "blue-400"
      } md:flex relative transform hover:scale-105 hover:text-black`}
    >
      {Icon && (
        <div className={`md:flex-shrink-0 flex justify-start items-center ml-8 text-${fromColor || "green-500"}`}>
          <Icon className="h-16 w-16 mb-4 mt-4" />
        </div>
      )}
      <div className="p-4">
        <div className={`uppercase tracking-wide text-sm text-${fromColor || "green-500"} font-semibold leading-none`}></div>
        <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
        <p className="mt-1 pr-1 text-sm">{desc}</p>
      </div>
    </div>
  </div>
);

const ToolForm = ({ Icon, title, desc, to, fromColor, toColor, api, onClick }) => (
  <form action={to} method="POST" className="flex relative" onClick={onClick}>
    <input type="hidden" name="token" value={api.defaults.headers.common["x-access-token"]} />
    <button type="submit" className="flex-1 text-left">
      <div
        className={`absolute inset-0 bg-gradient-to-r from-${fromColor || "green-400"} to-${
          toColor || "blue-500"
        } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl`}
      ></div>
      <div
        className={`flex-1 bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border-t-2 border- hover:border-${
          fromColor || "blue-400"
        } md:flex relative transform hover:scale-105 hover:text-black`}
      >
        {Icon && (
          <div className={`md:flex-shrink-0 flex justify-start items-center ml-8 text-${fromColor || "green-500"}`}>
            <Icon className="h-16 w-16 mb-4 mt-4" />
          </div>
        )}
        <div className="p-4">
          <div className={`uppercase tracking-wide text-sm text-${fromColor || "green-500"} font-semibold leading-none`}></div>
          <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
          <p className="mt-1 pr-1 text-sm">{desc}</p>
        </div>
      </div>
    </button>
  </form>
);

const Tool = ({ Icon, title, desc, to, fromColor, toColor }) => (
  <Link to={to} className="flex relative">
    <div className="flex-1 text-left">
      <div
        className={`absolute inset-0 bg-gradient-to-r from-${fromColor || "green-400"} to-${
          toColor || "blue-500"
        } shadow-lg transform skew-y-0 -rotate-3 rounded-3xl`}
      ></div>
      <div
        className={`flex-1 bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border-t-2 border- hover:border-${
          fromColor || "blue-400"
        } md:flex relative transform hover:scale-105 hover:text-black`}
      >
        {Icon && (
          <div className={`md:flex-shrink-0 flex justify-start items-center ml-8 text-${fromColor || "green-500"}`}>
            <Icon className="h-16 w-16 mb-4 mt-4" />
          </div>
        )}
        <div className="p-4">
          <div className={`uppercase tracking-wide text-sm text-${fromColor || "green-500"} font-semibold leading-none`}></div>
          <div className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">{title}</div>
          <p className="mt-1 pr-1 text-sm">{desc}</p>
        </div>
      </div>
    </div>
  </Link>
);

export default withRouter(Body);
