import React, { Component, useEffect, useState } from "react";
import { PencilIcon, ClockIcon, InformationCircleIcon, DuplicateIcon } from "@heroicons/react/outline";

import { Helmet } from "react-helmet";

import Header from "../Components/Header";
import Body, { Grid, Col } from "../Components/Body";
import Button from "../Components/Button";
import Output from "../Components/Output";
import Countdown from "react-countdown";
import { withRouter } from "react-router-dom";
import HandwritingModal from "./HandwritingModal";
import { handwritingConfig } from "../tools/HandwritingConfig";
import HandwritingConfigContext from "./HandwritingConfigContext";
import { connectedSockets, getCurrentSocketId } from "../utils/sharedSockets";

import { observable, makeObservable, computed } from "mobx";
import { observer, inject } from "mobx-react";

import EntryTabs from "../Components/EntryTabs";
import EntryPrompt from "../Components/EntryPrompt";
import EntryInput from "../Components/EntryInput";
import EntryN from "../Components/EntryN";

import Filter from "bad-words";
let filterBadWords = new Filter();

@inject("store")
@observer
class Tool extends Component {
  static contextType = HandwritingConfigContext;

  constructor(props) {
    super(props);
    makeObservable(this);

    this.isHandwritingModal = this.props.isHandwritingModal || false;
    this.tool = this.props.store.getToolByUrl(this.props.location.pathname);

    // ...

    console.log("Tool props:", props); // Add this line
    const { store, location, match, forceConfig } = this.props;

    if (!this.tool) {
      window.location.href = "/";
    } else {
      this.prompts = [...this.tool.prompts];
    }

    // src/Core/Tool.js
    this.state = {
      showModal: false,
    };

    this.fileRef = React.createRef();
    this.date = Date.now() + 1000;
    this.countdown = [];
    this.isHandwritingModalVisible = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config !== this.props.config) {
      this.setState({ config: this.props.config });
    } else if (prevProps.forceConfig !== this.props.forceConfig) {
      this.setState({ config: this.props.forceConfig });
    }
    console.log("Tool componentDidUpdate - prevProps:", prevProps);
    console.log("Tool componentDidUpdate - nextProps:", this.props);
    console.log("Tool componentDidUpdate - prevState:", this.state);

    const hasFileInput = this.prompts[this.currentPrompt].prompts.some((prompt) => prompt.type === "file");
    this.hasFileInput = hasFileInput;
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  @observable tool = {};

  @observable.deep prompts = [];
  @observable currentPrompt = 0;
  @observable currentOption = "Start Using";

  @observable error = "";
  @observable hasFileInput = false;

  @observable output = "";
  @observable scores = [];

  @observable outputs = [];
  @observable code = "";

  fileRef = React.createRef();

  @observable loading = false;

  @observable date = Date.now() + 1000;
  countdown = [];

  @observable isHandwritingModalVisible = false;

  @computed get isGenerateButtonDisabled() {
    if (this.loading) {
      return true;
    }

    return false;
  }

  @computed get disabled() {
    if (this.prompts[this.currentPrompt].prompts[0].value.length < 1) {
      return true;
    }

    // this.prompts[this.currentPrompt].prompts[promptIndex].value
    return false;
  }

  @computed get isMinLength() {
    if (!this.props.prompt.min) {
      return false;
    }
    if (!this.props.prompt.type === "number") {
      return false;
    }

    return false;
  }

  checkMinimumPrompts = () => {
    let shouldReturn = false;

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      if (prompt.min) {
        if (prompt.value.length < prompt.min) {
          shouldReturn = true;
          prompt.error = `${prompt.title} needs to meet the minimum ${prompt.min} characters`;
        }
      }
    });

    return shouldReturn;
  };

  clearExampleTimeout = [];

  onStartUsing = async () => {
    this.loading = false;
    this.error = "";
    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });
    this.currentOption = "Start Using";
  };

  onExample = async () => {
    // Check if there's a file input. If so, return immediately
    const hasFileInput = this.prompts[this.currentPrompt].prompts.some((prompt) => prompt.type === "file");
    if (hasFileInput) {
      return;
    }
    this.loading = true;
    this.error = "";
    this.output = "";
    this.outputs = [];
    this.code = ``;

    this.currentOption = "Example";

    let totalLength = 0;

    this.clearExampleTimeout.forEach((item, index) => {
      clearTimeout(this.clearExampleTimeout[index]);
    });

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      this.prompts[this.currentPrompt].prompts[promptIndex].value = "";
    });

    this.prompts[this.currentPrompt].prompts.forEach((prompt, promptIndex) => {
      for (let timeoutIndex = 0; timeoutIndex < prompt.example.length; timeoutIndex++) {
        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.prompts[this.currentPrompt].prompts[promptIndex].value += prompt.example[timeoutIndex];
        }, 7 * totalLength);
      }
    });

    totalLength++;

    if (this.prompts[this.currentPrompt].example.output) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.output = this.prompts[this.currentPrompt].example.output;
        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = "Start Using";
          this.prompts[this.currentPrompt].prompts[0].value += " ";
        }, 7 * totalLength + this.prompts[this.currentPrompt].example.output.length * 7 + 500);
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.code) {
      totalLength++;
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.code = `${this.prompts[this.currentPrompt].example.code}`;
        this.loading = false;
      }, 7 * totalLength + 500);
    }

    if (this.prompts[this.currentPrompt].example.outputs) {
      this.clearExampleTimeout[totalLength] = setTimeout(() => {
        this.outputs = this.prompts[this.currentPrompt].example.outputs;

        totalLength++;
        this.clearExampleTimeout[totalLength] = setTimeout(() => {
          this.loading = false;
          this.currentOption = "Start Using";
          // this.prompts[this.currentPrompt].prompts[0].value += " "
        }, 7 * totalLength + 500);
      }, 7 * totalLength + 500);
    }
  };

  sanitizeAllPrompts = () => {
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (!prompt.value) {
        return false;
      }
      if (prompt.type === "number") {
        return false;
      }

      prompt.value = prompt.value.trim();

      if (filterBadWords.isProfane(prompt.value)) {
        prompt.error = "Unsafe content , please try different language";
        throw Error("Unsafe content");
      }
    });
  };

  contentFilterFlagged = async (response) => {
    this.error = response.message;

    this.date = Date.now() + 5000;
    this.countdown.forEach((countdown) => {
      if (countdown) {
        countdown.stop();
        countdown.start();
      }
    });
    this.loading = false;
  };

  checkOutput = (output) => {
    if (output) {
      output = output.replace(/^\s+|\s+$/g, "");
      // output = output.replace(/\s{2,}/g, ' ')
    }
    return output;
  };

  handleFileChange = (e, prompt) => {
    prompt.file = e.target.files[0];
  };
  @computed get language() {
    let language = "";
    this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
      if (prompt.attr === "language") {
        language = `${prompt.value}`;
      }
    });
    return language;
  }

  getModalConfiguration = () => {
    console.log("HandwritingConfig:", this.context);
    return this.context;
  };

  onGenerateClick = async () => {
    try {
      this.error = "";
      this.output = "";
      this.code = ``;
      this.outputs = [];
      this.loading = true;

      console.log("Current socketId:", getCurrentSocketId());

      let checkMinimumPrompts = this.checkMinimumPrompts();
      if (checkMinimumPrompts) {
        this.loading = false;
        return false;
      }

      let hasFileInput = this.prompts[this.currentPrompt].prompts.some((prompt) => prompt.type === "file");

      let hasSpecificFileInputs = this.prompts[this.currentPrompt].prompts.some(
        (prompt) => prompt.type === "file" && (prompt.name === "scheme" || prompt.name === "essays1" || prompt.name === "essays2")
      );

      let data = {};
      data.socketId = getCurrentSocketId();
      console.log("Sending socketId:", data.socketId);

      let config = {};
      let response;

      if (hasFileInput) {
        this.formData = new FormData();
        this.formData.append("socketId", getCurrentSocketId());

        this.prompts[this.currentPrompt].prompts.forEach((prompt) => {
          if (prompt.type === "file" && prompt.file) {
            if (hasSpecificFileInputs) {
              if (prompt.name === "scheme" || prompt.name === "essays1" || prompt.name === "essays2") {
                this.formData.append(prompt.name, prompt.file, prompt.file.name);
              }
            } else {
              const isImage = prompt.file.type.startsWith("image/");
              const formFieldName = isImage ? "imageFiles" : "essays";
              console.log(`Appending file to FormData: isImage=${isImage}, formFieldName=${formFieldName}, fileName=${prompt.file.name}`);
              this.formData.append(formFieldName, prompt.file, prompt.file.name);
            }
          } else {
            this.formData.append(prompt.attr, prompt.value);
          }
        });

        config = {
          headers: {},
        };

        response = await this.props.store.api.post(this.tool.api, this.formData, config);
      } else {
        data = {
          ...data,
          ...this.prompts[this.currentPrompt].prompts.reduce(function (obj, prompt) {
            obj[prompt.attr] = prompt.value;
            return obj;
          }, {}),
        };

        let essays = [];
        const attrs = Object.keys(data);
        const essayAttrs = attrs.filter((attr) => attr.startsWith("essay_"));
        for (let attr of essayAttrs) {
          const titleAttr = attr.replace("essay", "essay_title");
          if (data[titleAttr]) {
            essays.push({ essay: data[attr], essay_title: data[titleAttr] });
            delete data[attr];
            delete data[titleAttr];
          }
        }

        // Send essays with the API request
        data.essays = essays;

        config = {
          headers: {
            "content-type": "application/json",
          },
        };

        response = await this.props.store.api.post(this.tool.api, data, config);
      }

      if (!response.data.success) {
        this.contentFilterFlagged(response.data);
        return false;
      }

      if (response.data.output) {
        this.output = this.checkOutput(response.data.output);
      }

      if (response.data.code) {
        this.code = response.data.code;
      }

      if (response.data.outputs) {
        this.outputs = response.data.outputs;
        this.scores = response.data.scores; // Add this line to store the scores
      }

      this.date = Date.now() + 10000;
      this.countdown.forEach((countdown) => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    } catch (error) {
      console.log(error);
      this.error = "Something went wrong, please try again";
      this.countdown.forEach((countdown) => {
        if (countdown) {
          countdown.stop();
          countdown.start();
        }
      });
      this.loading = false;
    }
  };

  render() {
    console.log("Tool render - config prop:", this.props.config);
    console.log("Tool render - config:", this.props.config);

    const config = this.context;

    // required for mobx to pick up deeply nested value
    const currentValue = this.prompts[this.currentPrompt].prompts[0].value;

    return (
      <>
        <Helmet>
          <title>{`${this.tool.title} Tool - Top Marks AI`}</title>
        </Helmet>
        <Header
          title={this.tool.title}
          desc={this.tool.desc}
          Icon={this.tool.Icon}
          fromColor={this.tool.fromColor}
          category={this.tool.category}
          options={[
            {
              title: "Start Using",
              Icon: PencilIcon,
              color: this.props.store.profile.credits ? "green" : "red",
              onClick: this.onStartUsing,
            },
            {
              title: "Example",
              color: "yellow",
              Icon: InformationCircleIcon,
              onClick: this.onExample,
              disabled: this.hasFileInput,
              // apply some CSS when disabled
              style: this.hasFileInput ? { pointerEvents: "none", opacity: 0.5 } : {},
            },
          ]}
          currentOption={this.currentOption}
        />
        <Body>
          <Button
            title="Open Handwriting to Text Converter"
            onClick={() => {
              console.log("Button clicked");
              this.openModal();
            }}
          />{" "}
          <HandwritingModal showModal={this.state.showModal} closeModal={this.closeModal} store={this.props.store} />
          <Grid>
            <Col span="6">
              <EntryTabs prompts={this.prompts} currentPrompt={this.currentPrompt} onChange={this.handleCurrentPrompt} />

              {this.prompts.map((prompt, index) => (
                <EntryPrompt
                  prompt={prompt}
                  key={index}
                  index={index}
                  disabled={this.disabled}
                  currentPrompt={this.currentPrompt}
                  extraButtons={prompt.extraButtons}
                >
                  {prompt.prompts.map((promptInput, index) => (
                    <EntryInput
                      prompt={promptInput}
                      key={index}
                      language={this.language}
                      index={index}
                      disabled={this.disabled}
                      fileRef={this.fileRef}
                      onFileChange={(e) => this.handleFileChange(e, promptInput)}
                      onPerformRequest={this.onGenerateClick}
                    />
                  ))}

                  <div className="md:flex">
                    <Countdown
                      ref={(countdown) => (this.countdown[index] = countdown)}
                      date={this.date}
                      renderer={(props) => (
                        <Button
                          title={props.total ? `Timeout ${props.total / 1000} secs` : "Perform Request"}
                          disabled={props.total || this.isGenerateButtonDisabled}
                          Icon={props.total ? ClockIcon : currentValue ? DuplicateIcon : PencilIcon}
                          onClick={this.onGenerateClick}
                        />
                      )}
                    />
                    <EntryN prompts={this.prompts} currentPrompt={this.currentPrompt} />
                  </div>

                  {this.error && (
                    <div className="mt-4">
                      <label className={`${this.error ? "text-red-400" : "text-gray-400"} font-medium transition-all`}>{this.error}</label>
                    </div>
                  )}
                </EntryPrompt>
              ))}
            </Col>
            <Col span="6">
              <Output
                title={this.tool.output.title}
                desc={this.tool.output.desc}
                Icon={this.tool.output.Icon || this.tool.Icon}
                fromColor={this.tool.fromColor}
                toColor={this.tool.toColor}
                loading={this.loading}
                scores={this.scores}
                output={this.output}
                outputs={this.outputs}
                code={this.code}
                language={this.language}
                outputsColor={this.tool.output.color}
                OutputsIcon={this.tool.output.Icon}
              />
            </Col>
          </Grid>
        </Body>
      </>
    );
  }
}

export default withRouter(Tool);
