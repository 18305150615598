import React, { useState } from "react";
import { observer, inject } from "mobx-react";
import {
  CheckCircleIcon,
  BanIcon,
  DatabaseIcon,
  AcademicCapIcon,
  PencilIcon,
  MenuAlt1Icon,
  LightBulbIcon,
  SparklesIcon,
  UserIcon,
  CurrencyDollarIcon,
  UserCircleIcon,
  DocumentIcon,
} from "@heroicons/react/solid";
import { GlobeIcon } from "@heroicons/react/outline";
import Logo from "./Logo";
import newlogo from "./new-logo.png";
import config from "./config";
import CurrencyToggle from "./CurrencyToggle";

const PricingOption = ({ title, price, features, fromColor, toColor, baseURL, api, priceId, currency }) => {
  const currencySymbol = currency === "GBP" ? "£" : "$";
  return (
    <div className="flex relative">
      <div
        className={`absolute inset-0 bg-gradient-to-r from-${fromColor} to-${toColor} shadow-lg transform skew-y-0 -rotate-3 rounded-3xl`}
      ></div>
      <div
        className={`bg-white rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 border-t-2 hover:border-${fromColor} md:flex relative transform hover:scale-105 hover:text-black flex-1`}
      >
        <div className="p-8 flex-1">
          <div className={`text-${fromColor} block text-lg text-2xl leading-tight font-medium mb-2`}>{title}</div>
          <div className="text-6xl text-black font-bold">
            {currencySymbol}
            {price}
            <span className="text-lg text-gray-400">{title === "Free" ? " free trial" : "/per month"}</span>
          </div>
          <div className="divide-y divide-dashed divide-gray-300 mt-4">
            {features.map((feature, index) => (
              <div key={index} className="py-2 flex items-center">
                <feature.icon className={`w-6 h-6 mr-2 text-${fromColor}`} />
                <div>
                  <span className="font-medium text-black">{feature.text}</span>
                </div>
              </div>
            ))}
          </div>
          <form action={baseURL + "user/stripe/subscribe"} method="POST" className="flex flex-1">
            <input type="hidden" name="token" value={api.defaults.headers.common["x-access-token"]} />
            <input type="hidden" name="priceId" value={priceId} />
            <input type="hidden" name="currency" value={currency} />
            <button
              type="submit"
              className={`mt-8 inset-0 bg-gradient-to-r from-${fromColor} to-${toColor} shadow-lg flex-1 rounded-md p-4 text-white font-medium text-center text-lg transition hover:from-gray-700 hover:to-gray-800 text-enter`}
            >
              {title === "Free" ? "Try Out" : "Get Started"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const Pricing = inject("store")(
  observer(({ store }) => {
    const [currency, setCurrency] = useState("GBP");

    const pricingOptions = [
      {
        title: "Free",
        price: "0",
        fromColor: "gray-400",
        toColor: "gray-500",
        features: [
          { icon: DatabaseIcon, text: "5 Credits" },
          { icon: MenuAlt1Icon, text: "5 essay marking requests" },
          { icon: AcademicCapIcon, text: "All marking rubrics" },
          { icon: PencilIcon, text: "Handwriting to Editable Text Tool" },
          { icon: UserIcon, text: "Stellar customer support" },
          { icon: DocumentIcon, text: "Word Integration: download all results to word" },
        ],
        priceId: config.stripe[currency.toLowerCase()].free,
      },
      {
        title: "Entry",
        price: currency === "GBP" ? "20" : "25",
        fromColor: "green-400",
        toColor: "green-600",
        features: [
          { icon: DatabaseIcon, text: "40 Credits" },
          { icon: MenuAlt1Icon, text: "40 essay marking requests" },
          { icon: AcademicCapIcon, text: "All marking rubrics" },
          { icon: PencilIcon, text: "Handwriting to Editable Text Tool" },
          { icon: UserIcon, text: "Stellar customer support" },
          { icon: DocumentIcon, text: "Word Integration: download all results to word" },
        ],
        priceId: config.stripe[currency.toLowerCase()].entry,
      },
      {
        title: "Pro",
        price: currency === "GBP" ? "40" : "50",
        fromColor: "indigo-500",
        toColor: "red-500",
        features: [
          { icon: DatabaseIcon, text: "90 Credits" },
          { icon: MenuAlt1Icon, text: "90 essay marking requests" },
          { icon: AcademicCapIcon, text: "All marking rubrics" },
          { icon: PencilIcon, text: "Handwriting to Editable Text Tool" },
          { icon: UserIcon, text: "Stellar customer support" },
          { icon: DocumentIcon, text: "Word Integration: download all results to word" },
        ],
        priceId: config.stripe[currency.toLowerCase()].pro,
      },
    ];

    return (
      <>
        {!store.profile.status && (
          <div className="border-b border-gray-300 bg-white shadow-sm">
            <div className="container flex mx-auto px-4 md:px-28 flex select-none">
              <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1"></div>
              <div
                onClick={store.handleLogout}
                className="cursor-pointer text-lg flex py-3 px-6 xl:py-4 xl:px-8 hover:bg-gray-100 rounded-t-md font-medium transition items-center"
              >
                <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
                <div className="hidden lg:block"> Log Out</div>
              </div>
            </div>
          </div>
        )}
        <div className="container mx-auto px-8 py-4 lg:px-28 lg:py-12 lg:pb-64 select-none">
          {!store.profile.status && (
            <>
              <div className="text-center">
                <img
                  src={newlogo}
                  className="inline-block"
                  style={{ width: "100px", height: "100px", objectFit: "contain" }}
                  alt="TopMarks.ai Logo"
                />
              </div>
              <h2 className="text-xl sm:text-2xl md:text-5xl text-gray-700 text-center">Start now</h2>
              <p className="text-lg sm:text-xl md:text-2xl text-gray-400 mb-14 mt-2 text-center">
                Pick a pricing option to begin using TopMarks.ai
              </p>
            </>
          )}

          <div className="flex justify-end mb-8">
            <CurrencyToggle currency={currency} setCurrency={setCurrency} />
          </div>

          <Grid>
            {!store.profile.status && (
              <PricingOption
                title="Free"
                price="0"
                fromColor="gray-400"
                toColor="gray-500"
                baseURL={store.baseURL}
                api={store.api}
                priceId={config.stripe[currency.toLowerCase()].free}
                currency={currency}
                features={[
                  { icon: DatabaseIcon, text: "5 Credits" },
                  { icon: MenuAlt1Icon, text: "5 essay marking requests" },
                  { icon: AcademicCapIcon, text: "All marking rubrics" },
                  { icon: PencilIcon, text: "Handwriting to Editable Text Tool" },
                  { icon: UserIcon, text: "Stellar customer support" },
                  { icon: DocumentIcon, text: "Word Integration: download all results to word" },
                ]}
              />
            )}
            <PricingOption
              title="Entry"
              price={currency === "GBP" ? "20" : "25"}
              fromColor="green-400"
              toColor="green-600"
              baseURL={store.baseURL}
              api={store.api}
              priceId={config.stripe[currency.toLowerCase()].entry}
              currency={currency}
              features={[
                { icon: DatabaseIcon, text: "40 Credits" },
                { icon: MenuAlt1Icon, text: "40 essay marking requests" },
                { icon: AcademicCapIcon, text: "All marking rubrics" },
                { icon: PencilIcon, text: "Handwriting to Editable Text Tool" },
                { icon: UserIcon, text: "Stellar customer support" },
                { icon: DocumentIcon, text: "Word Integration: download all results to word" },
              ]}
            />
            <PricingOption
              title="Pro"
              price={currency === "GBP" ? "40" : "45"}
              fromColor="indigo-500"
              toColor="red-500"
              baseURL={store.baseURL}
              api={store.api}
              priceId={config.stripe[currency.toLowerCase()].pro}
              currency={currency}
              features={[
                { icon: DatabaseIcon, text: "90 Credits" },
                { icon: MenuAlt1Icon, text: "90 essay marking requests" },
                { icon: AcademicCapIcon, text: "All marking rubrics" },
                { icon: PencilIcon, text: "Handwriting to Editable Text Tool" },
                { icon: UserIcon, text: "Stellar customer support" },
                { icon: DocumentIcon, text: "Word Integration: download all results to word" },
              ]}
            />
          </Grid>
        </div>
      </>
    );
  })
);

const Grid = ({ children }) => <div className="grid grid-cols-1 gap-12 mt-4 xl:grid-cols-3">{children}</div>;

export default Pricing;
