import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import mypeng from "./mypeng.png";
import ibenglishlit from "./ibenglishlit.png";
import ibenglishlang from "./ibenglishlang.png";
import ibTwo from "./ibhistory.png";
import globalpol from "./globalpol.png";

const IBSection = ({ permissions }) => {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem("ibToolOrder");
    if (savedOrder) {
      setTools(JSON.parse(savedOrder));
    } else {
      const defaultTools = [
        { id: "eng-myp", to: "/eng-myp", img: mypeng, alt: "MYP English" },
        { id: "ib-english", to: "/ib-english", img: ibenglishlit, alt: "IB English Literature" },
        { id: "ib-englishlang", to: "/ib-englishlang", img: ibenglishlang, alt: "IB English Language and Literature" },
        { id: "ib-history", to: "/ib-history", img: ibTwo, alt: "IB History" },
        { id: "global-pol", to: "/global-pol", img: globalpol, alt: "Global Politics" },
      ];
      setTools(defaultTools);
      localStorage.setItem("ibToolOrder", JSON.stringify(defaultTools));
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newTools = Array.from(tools);
    const [reorderedItem] = newTools.splice(result.source.index, 1);
    newTools.splice(result.destination.index, 0, reorderedItem);

    setTools(newTools);
    localStorage.setItem("ibToolOrder", JSON.stringify(newTools));
  };

  return (
    <>
      <div className="title-container">
        <h2 className="title-text second-title-bubble">International Baccalaureate Marking Tools</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="ib-tools" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 custom-grid"
            >
              {tools.map((tool, index) => (
                <Draggable key={tool.id} draggableId={tool.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex flex-col items-center"
                    >
                      <Link to={tool.to} className="flex flex-col items-center w-full">
                        <img className="tool-img w-full mb-4" src={tool.img} alt={tool.alt} />
                        <button className="tool-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg w-3/4 flex items-center justify-center">
                          Browse
                        </button>
                      </Link>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default IBSection;
