import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import edexenglish from "./edexenglish.png";
import eightplus from "./edexcelenglish.png";
import edexengl from "./edexengl.png";
import edexgeog from "./edexgeog.png";
import edexbus from "./edexbus.png";
import edexcelrs from "./edexcelrs.png";
import sixplus from "./edexcelhistory.png";
import edexihist from "./edexihist.png";
import sevenplus from "./edexcelecon.png";
import geogedex from "./geogedex.png";
import aedexcelhist from "./aedexcelhist.png";
import psych from "./psych.png";
import poledex from "./poledex.png";
import busedex from "./busedex.png";

const EdexcelSection = ({ permissions }) => {
  const [tools, setTools] = useState([]);

  useEffect(() => {
    const savedOrder = localStorage.getItem("edexcelToolOrder");
    if (savedOrder) {
      setTools(JSON.parse(savedOrder));
    } else {
      const defaultTools = [
        { id: "edexcel-english-gcse", to: "/edexcel-english", img: eightplus, alt: "Edexcel English GCSE" },
        { id: "english-edexcel-gcse", to: "/english-edexcel", img: edexengl, alt: "Edexcel English GCSE (Alt)" },
        { id: "edex-geog-gcse", to: "/edex-geog", img: edexgeog, alt: "Edexcel Geography GCSE" },
        { id: "edex-bus-gcse", to: "/edex-bus", img: edexbus, alt: "Edexcel Business GCSE" },
        { id: "edexcel-rs", to: "/edexcel-rs", img: edexcelrs, alt: "Edexcel Religious Studies GCSE" },
        { id: "edexcel-history-gcse", to: "/edexcel-history", img: sixplus, alt: "Edexcel History GCSE" },
        { id: "edexcel-ihistory", to: "/edexcel-ihistory", img: edexihist, alt: "Edexcel International History GCSE" },
        { id: "edex-english-alevel", to: "/edex-english", img: edexenglish, alt: "Edexcel English A-Level" },
        { id: "edexcel-economics-alevel", to: "/edexcel-economics", img: sevenplus, alt: "Edexcel Economics A-Level" },
        { id: "geog-edex-alevel", to: "/geog-edex", img: geogedex, alt: "Edexcel Geography A-Level" },
        { id: "history-edexcel-alevel", to: "/history-edexcel", img: aedexcelhist, alt: "Edexcel History A-Level" },
        { id: "psych-edex-alevel", to: "/psych-edex", img: psych, alt: "Edexcel Psychology A-Level" },
        { id: "pol-edex-alevel", to: "/pol-edex", img: poledex, alt: "Edexcel Politics A-Level" },
        { id: "bus-edex-alevel", to: "/bus-edex", img: busedex, alt: "Edexcel Business A-Level" },
      ];
      setTools(defaultTools);
      localStorage.setItem("edexcelToolOrder", JSON.stringify(defaultTools));
    }
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newTools = Array.from(tools);
    const [reorderedItem] = newTools.splice(result.source.index, 1);
    newTools.splice(result.destination.index, 0, reorderedItem);

    setTools(newTools);
    localStorage.setItem("edexcelToolOrder", JSON.stringify(newTools));
  };

  return (
    <>
      <div className="title-container">
        <h2 className="title-text title-bubble">Edexcel Tools</h2>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="edexcel-tools" direction="horizontal">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 custom-grid"
            >
              {tools.map((tool, index) => (
                <Draggable key={tool.id} draggableId={tool.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="flex flex-col items-center"
                    >
                      <Link to={tool.to} className="flex flex-col items-center w-full">
                        <img className="tool-img w-full mb-4" src={tool.img} alt={tool.alt} />
                        <button className="tool-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded text-lg w-3/4 flex items-center justify-center">
                          Browse
                        </button>
                      </Link>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default EdexcelSection;
