import jobad from "./jobad";
import codeInterpret from "./interpret";
import intro from "./intro";
import helloworld from "./helloworld";
import example from "./example";
import sats from "./SATs Paper Generator";

import handwriting from "./Handwriting";

import handwritingtwo from "./HandwritingConfig";

import lease from "./Lease";

import transcribe from "./transcribe";

import eaton from "./eaton";
import eaton2 from "./eaton2";
import eaton3 from "./eaton3";

import sampletarigo from "./sampletarigo";

import split from "./split";

const TOOLS = [handwriting, handwritingtwo, transcribe, split];

export default TOOLS;
