import React, { Component } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { computed } from "mobx";
import { NavLink } from "react-router-dom";
import { SwitchHorizontalIcon, ScaleIcon, DatabaseIcon, UserCircleIcon, ClockIcon } from "@heroicons/react/outline";
import { IconDashboard } from "./Icons";
import { useLocation } from "react-router-dom";
import Body from "./Components/Body";
import { withRouter } from "react-router-dom";
import newlogo from "./new-logo.png";

function HeaderExpand(props) {
  const location = useLocation();
  return <SuperHeader active={location.pathname === "/"}>{props.children}</SuperHeader>;
}

@inject("store")
@observer
class SidebarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFormModal: false, // State to manage the modal visibility
    };

    if (this.props.location.pathname === "/signup") {
      this.props.history.push("/");
    }
    if (this.props.location.pathname === "/login") {
      this.props.history.push("/");
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.shortcutHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.shortcutHandler);
  }

  shortcutHandler = (e) => {
    if (e.keyCode === 75 && e.ctrlKey) {
      e.preventDefault();
      document.getElementById("q").focus();
      document.getElementById("q").select();
    }
  };

  onKeyUp = (e) => {
    if (this.props.location.pathname !== "/search") {
      this.props.history.push("/search");
    }
    if (e.keyCode === 8) {
      if (this.props.store.toolsKeyword === "") {
        this.props.history.push("/");
      }
    }
  };

  @computed get fromColor() {
    if (this.props.store.profile.credits <= 0) {
      return "bg-red-200 text-red-600";
    }
    if (this.props.store.profile.status === "trialing") {
      return "";
    }
    if (this.props.store.profile.status === "active") {
      return "";
    }
    if (this.props.store.profile.status === "incomplete") {
      return "";
    }
    return "bg-red-200 text-red-600";
  }

  toggleFormModal = () => {
    this.setState((prevState) => ({ showFormModal: !prevState.showFormModal }));
  };

  render() {
    return (
      <>
        <Textarea readOnly name="copy-textarea" id="copy-textarea" value={this.props.store.copyToClipboardText} />
        <HeaderExpand>
          <Body className="px-4 py-4 md:px-28 md:py-8 lg:py-12 flex items-center flex-1">
            <div className="mr-4">
              <NavLink to="/">
                <img src={newlogo} alt="Logo" />
              </NavLink>
            </div>
            <div>
              <div className="text-4xl relative font-medium text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-600 inline-block">
                Top<span className="font-normal">Marks</span>
                <div className="absolute top-0 ml-3 left-full bg-gradient-to-br from-gray-500 to-gray-500 text-white text-sm px-2 py-0.5 rounded-md font-normal hidden md:flex">
                  AI
                </div>
              </div>
              <div className="hidden md:block text-xl text-transparent bg-clip-text bg-gradient-to-r from-gray-700 to-gray-500 inline-block">
                Automate marking. Get on with teaching!
              </div>
              <div className="flex">
                <div
                  className={`items-center flex inline-flex ${
                    this.props.store.profile.credits ? "bg-gray-100 text-gray-500" : "bg-red-100 text-red-500"
                  } text-sm rounded-md px-3 py-1 font-medium my-2 mr-2`}
                >
                  <DatabaseIcon className="w-4 h-4 mr-2" />
                  {this.props.store.profile.credits}&nbsp;<span className="hidden lg:block">credits remain</span>
                </div>
              </div>
            </div>
          </Body>
        </HeaderExpand>

        <div className="border-b border-gray-300 bg-white shadow-sm">
          <div className="container flex mx-auto px-4 md:px-28 flex select-none">
            <NavLink
              to="/"
              exact
              tabIndex={-1}
              onClick={() => (this.props.store.toolsKeyword = "")}
              activeClassName="bg-gray-100 hover:bg-gray-200 text-gray-800 transition"
              className="text-lg flex py-3 px-6 lg:py-4 lg:px-8 cursor-pointer hover:bg-gray-100 rounded-t-md font-medium transition items-center"
            >
              <IconDashboard className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">Tools</div>
            </NavLink>

            <div className="relative text-gray-400 focus-within:text-green-500 flex flex-1">
              <label htmlFor="q" className="absolute inset-y-0 left-0 top-0 bottom-0 hidden md:flex items-center lg:pl-2">
                <div type="submit" className="p-2 focus:outline-none focus:shadow-outline">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    className="w-6 h-6 transition"
                  >
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </div>
              </label>
              <Input
                type="search"
                tabIndex={-1}
                id="q"
                name="q"
                className="py-4 pl-4 md:pl-14 text-xl focus:outline-none focus:bg-white focus:text-gray-900 transition flex flex-1 w-full"
                placeholder="Search...  [Shortcut: Ctrl + K]"
                autoComplete="off"
                value={this.props.store.toolsKeyword}
                onChange={this.props.store.onChangeToolsKeyword}
                onKeyUp={this.onKeyUp}
              />
            </div>
            <NavLink
              to="/history"
              exact
              tabIndex="-1"
              activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
              className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-100 rounded-t-md font-medium transition items-center`}
            >
              <ClockIcon className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">History</div>
            </NavLink>
            <NavLink
              to="/my-profile"
              exact
              tabIndex="-1"
              activeClassName="bg-green-100 hover:bg-green-200 text-green-800 transition"
              className={`text-lg flex py-3 px-6 xl:py-4 xl:px-8 cursor-pointer ${this.fromColor} hover:bg-gray-100 rounded-t-md font-medium transition items-center`}
            >
              <UserCircleIcon className="w-7 h-7 lg:mr-4 transition" />
              <div className="hidden lg:block">My Profile</div>
            </NavLink>

            {/* Add Suggest Button Here */}
            <SuggestButton onClick={this.toggleFormModal}>Suggest a Tool/Subject</SuggestButton>
          </div>
        </div>

        {this.props.children}

        {/* Modal Popup for Google Form */}
        {this.state.showFormModal && (
          <ModalOverlay>
            <ModalContent>
              <button onClick={this.toggleFormModal} className="close-button">
                &times;
              </button>
              <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfWY0nsrl6Flf-dBfAGyCI6R4BuezzqTlsz2lybZ_X6zhQxNg/viewform?embedded=true"
                width="100%"
                height="500"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="Suggestion Form"
              >
                Loading…
              </iframe>
            </ModalContent>
          </ModalOverlay>
        )}
      </>
    );
  }
}

const Input = styled.input``;

const Textarea = styled.textarea`
  position: fixed;
  right: -9990px;
  top: -9990px;
`;

const SuperHeader = styled.div`
  height: 150px;
  background: white;
  margin-top: ${(props) => (props.active ? "0px" : "-150px")};
  display: ${(props) => (props.hidden ? "hidden" : "flex")};
  background-image: url(${require("./pattern-dots.svg").default});
  background-size: auto 50%;
  background-position: 20px 20px;
  background-repeat: no-repeat;
  position: relative;
`;

const SuggestButton = styled.button`
  background-color: #3b82f6; /* Tailwind Blue 500 */
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  margin-left: auto;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2563eb; /* Tailwind Blue 600 */
    cursor: pointer;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  position: relative;
  width: 90%;
  max-width: 800px;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
`;

export default withRouter(SidebarComponent);
