import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Modal from "react-modal";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { Button, Form, Input, notification } from "antd";
import { Card } from "antd";
import { format } from "date-fns";

function convertToParagraphs(text) {
  return text.replace(/\n\n/g, "</p><p>");
}

@inject("store")
@observer
class UserStudents extends Component {
  state = {
    students: null,
    loading: true,
    modalIsOpen: false,
    historyModalIsOpen: false,
    nestedModalIsOpen: false,
    selectedStudent: null,
    selectedStudentHistories: null,
    selectedHistory: null,
  };

  componentDidMount() {
    this.loadStudents();
  }

  loadStudents = async () => {
    const { store } = this.props;
    const jwtToken = store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
    };

    try {
      const response = await fetch("http://localhost:3080/api/user/get-students", {
        method: "GET",
        headers: headers,
      });
      const data = await response.json();
      this.setState({ students: data.students || [], loading: false });
    } catch (error) {
      console.error("Failed to fetch students:", error);
      this.setState({ loading: false });
    }
  };

  getStudentHistories = async (studentId) => {
    const { store } = this.props;
    const jwtToken = store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
    };

    try {
      const response = await fetch(`http://localhost:3080/api/user/get-student-histories/${studentId}`, {
        method: "GET",
        headers: headers,
      });
      const data = await response.json();

      // Sort histories by date
      const sortedHistories = (data.histories || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.setState({ selectedStudentHistories: sortedHistories });
    } catch (error) {
      console.error("Failed to fetch student histories:", error);
    }
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  openHistoryModal = (student) => {
    this.setState(
      {
        historyModalIsOpen: true,
        selectedStudent: student,
      },
      () => {
        this.getStudentHistories(student._id);
      }
    );
  };

  closeHistoryModal = () => {
    this.setState({ historyModalIsOpen: false });
  };

  handleAddStudent = async (values) => {
    const { store } = this.props;
    const jwtToken = store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };

    try {
      const result = await store.api.post("/user/add-student", values, { headers: headers });
      if (result && result.status === 201) {
        this.setState((prevState) => ({
          students: [...prevState.students, result.data.student],
          modalIsOpen: false,
        }));
        notification.success({
          message: "Student Added",
          description: "The student has been added successfully.",
        });
      }
    } catch (error) {
      console.error("Failed to add student:", error);
      notification.error({
        message: "Error",
        description: "Failed to add student. Please try again.",
      });
    }
  };

  openNestedModal = (history) => {
    this.setState({
      nestedModalIsOpen: true,
      selectedHistory: history,
    });
  };

  closeNestedModal = () => {
    this.setState({ nestedModalIsOpen: false });
  };

  render() {
    const {
      students,
      loading,
      modalIsOpen,
      historyModalIsOpen,
      nestedModalIsOpen,
      selectedStudent,
      selectedStudentHistories,
      selectedHistory,
    } = this.state;

    if (loading) {
      return <div className="loading">Loading...</div>;
    }

    const bubbleStyle = {
      border: "1px solid black",
      padding: "10px",
      margin: "10px",
      cursor: "pointer",
      borderRadius: "10px",
      width: "18%",
      textAlign: "center",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
      transition: "all 0.3s ease-in-out",
      wordWrap: "break-word",
    };

    const titleStyle = {
      fontSize: "2em",
      color: "#FFFFFF",
      padding: "0px 20px",
      borderRadius: "20px",
      backgroundColor: "#305f80",
      boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
      border: "1px solid #FFFFFF",
      margin: "20px 0",
      display: "inline-block",
      textAlign: "center",
    };

    // New container style for centering
    const titleContainerStyle = {
      textAlign: "center",
      width: "100%", // Ensures that the container takes up the full width
      marginBottom: "20px", // Adds some space below the title, adjust as needed
    };

    const studentListStyle = {
      display: "flex",
      flexWrap: "wrap",
    };

    const containerStyle = {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      // ...other styles you may have or need
    };

    if (this.state.selectedHistory) {
      console.log("Input:", JSON.stringify(this.state.selectedHistory.input, null, 2));
      const convertedInput = convertToParagraphs(JSON.stringify(this.state.selectedHistory.input, null, 2));
      console.log("Converted Input:", convertedInput);
    }

    return (
      <div className="container">
        <div style={titleContainerStyle}>
          {" "}
          {/* Wrap the title in this new div */}
          <h2 style={titleStyle}>Your Students</h2>
        </div>
        <div className="students-list" style={studentListStyle}>
          {students && students.length > 0 ? (
            students.map((student, index) =>
              student ? (
                <div key={index} className="bubble" onClick={() => this.openHistoryModal(student)}>
                  {student.firstName} {student.lastNameInitial}.
                </div>
              ) : null
            )
          ) : (
            <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>
              No students found. Click the button below to add students.
            </div>
          )}
        </div>
        <Button onClick={this.openModal} className="button">
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <PlusCircleIcon className="h-6 w-6 mr-2" aria-hidden="true" />
            Add Student
          </div>
        </Button>

        <Modal isOpen={modalIsOpen} onRequestClose={this.closeModal} contentLabel="Add Student">
          <div className="close-button">
            <Button onClick={this.closeModal}>Close</Button>
          </div>
          <h2>Add a New Student</h2>
          <Form onFinish={this.handleAddStudent}>
            <Form.Item name="firstName" rules={[{ required: true, message: "Please input the first name!" }]}>
              <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item name="lastNameInitial" rules={[{ required: true, message: "Please input the last name initial!" }]}>
              <Input placeholder="Last Name Initial" maxLength={1} />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
              <Button onClick={this.closeModal}>Cancel</Button>
            </Form.Item>
          </Form>
          <Button onClick={this.closeModal}>Close</Button> {/* Add this line */}
        </Modal>
        <Modal isOpen={historyModalIsOpen} onRequestClose={this.closeHistoryModal} contentLabel="Student Histories">
          <div className="close-button">
            <Button onClick={this.closeHistoryModal}>Close</Button>
          </div>
          <h2 className="modal-title">
            {selectedStudent ? `${selectedStudent.firstName} ${selectedStudent.lastNameInitial}'s Essays` : ""}
          </h2>
          {selectedStudentHistories ? (
            selectedStudentHistories.map((history, index) => {
              const apiEndpoint = history.api.split("/").pop();
              let inputText = history.input.split("QUESTION:")[1] || ""; // split on 'QUESTION:'
              inputText = inputText.split(" ").slice(0, 10).join(" "); // get the first 10 words
              console.log(history.output);
              let score = history.output.match(/Final score:\s*([\s\S]*?)(\n|$)/);
              score = (score && score[1].trim()) || "";
              console.log("Score is: ", score);
              return (
                <Card
                  key={index}
                  title={`${apiEndpoint} - ${new Date(history.created).toLocaleDateString()}`}
                  className="card"
                  extra={
                    <a href="#" className="card-extra" onClick={() => this.openNestedModal(history)}>
                      More
                    </a>
                  }
                  style={{ width: 350, margin: "10px" }}
                >
                  <p>{inputText}</p>
                  <p>{score}</p>
                </Card>
              );
            })
          ) : (
            <div>Loading...</div>
          )}
          <Modal isOpen={nestedModalIsOpen} onRequestClose={this.closeNestedModal} contentLabel="History Details">
            <div className="close-button">
              <Button onClick={this.closeNestedModal}>Back</Button>
            </div>
            {/* Render selected history details */}
            {selectedHistory && (
              <>
                <div className="section-container">
                  <div className="section-title">Essay</div>
                  <div className="section-content" dangerouslySetInnerHTML={{ __html: convertToParagraphs(selectedHistory.input) }} />
                </div>

                <div className="section-container">
                  <div className="section-title">Feedback & Score</div>
                  <div className="section-content" dangerouslySetInnerHTML={{ __html: convertToParagraphs(selectedHistory.output) }} />
                </div>
              </>
            )}
          </Modal>
        </Modal>
      </div>
    );
  }
}

export default UserStudents;
