import { ThemeProvider } from "styled-components";
import React, { Component, useState } from "react";

import { Provider } from "mobx-react";
import { observer } from "mobx-react";

import AppStore from "./store";
import colors from "tailwindcss/colors";
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";

import Header from "./Header";
import Search from "./Search";
import Pricing from "./Pricing";
import Verification from "../src/Verification";
import PaymentDetails from "./Profile/PaymentDetails";
import Dashboard from "./Dashboard";
import Dashboard2 from "./Dashboard2";
import PasswordReset from "./Login/PasswordReset";
import Tool from "./Core/Tool";
import ToolDynamic from "./Core/ToolDynamic";

import Chat from "./Core/Chat";
import Login from "./Login/Login";
import Profile from "./Profile/";
import LoginSuccess from "./Login/Success";
import ForgotPassword from "./forgotPassword/index";
import ResetPassword from "./forgotPassword/ResetPassword";
import HandwritingModal from "./Core/HandwritingModal";
import Handwriting from "./tools/Handwriting";
import handwritingConfig from "./tools/HandwritingConfig";
import HandwritingTool from "./tools/HandwritingTool";
import EdexcelGeographyIGCSE from "./EdexcelGeographyIGCSE";

import AQAEnglishGCSE from "./AQAEnglishGCSE";
import CantabEnglishGCSE from "./CantabEnglishGCSE";
import CantabHistoryGCSE from "./CantabHistoryGCSE";

import EdexcelEnglishGCSE from "./EdexcelEnglishGCSE";
import EdexcelHistoryGCSE from "./EdexcelHistoryGCSE";
import EduqasEnglishGCSE from "./EduqasEnglishGCSE";
import EdexcelEconomics from "./EdexcelEconomics";
import KS3EngLang from "./KS3EngLang";
import ALevelAQAEnglish from "./ALevelAQAEnglish";
import IBEnglish from "./IBEnglish";
import IBHistory from "./IBHistory";
import IBEnglishMYP from "./IBEnglishMYP";
import AQAHistoryGCSE from "./AQAHistoryGCSE";

import AQAGeographyGCSE from "./AQAGeographyGCSE";

import UserHistory from "./UserHistory";
import IBGlobal from "./IBGlobal";
import UserStudents from "./UserStudents";

import OCREnglishGCSE from "./OCREnglishGCSE";
import OCREnglishALevel from "./OCREnglishALevel";
import OCREconomicsGCSE from "./OCREconomicsGCSE";
import OCRHistoryALevel from "./OCRHistoryALevel";
import OCRHistoryGCSE from "./OCRHistoryGCSE";
import OCRReligiousStudiesGCSE from "./OCRReligiousStudiesGCSE";
import EdexcelPol from "./EdexcelPol";
import AQARSGCSE from "./AQARSGCSE";
import IBEnglishLang from "./IBEnglishLang";
import WJECEnglish from "./WJECEnglish";

import EdexBusinessGCSE from "./EdexBusinessGCSE";
import EdexBusinessALevel from "./EdexBusinessALevel";

import EdexGeogALevel from "./EdexGeogALevel";

import EdexcelRSGCSE from "./EdexcelRSGCSE";

import AQABusinessALevel from "./AQABusinessALevel";

import AQADrama from "./AQADrama";

import EdexcelHistoryA from "./EdexcelHistoryA";

import EdexcelEnglishALevel from "./EdexcelEnglishALevel";

import EduqasEnglishALevel from "./EduqasEnglishALevel";
import CCEAEnglishGCSE from "./CCEAEnglishGCSE";
import KS2EngLang from "./KS2EngLang";
import SpectatorL2 from "./SpectatorL2";

import CIESoc from "./CIESoc";

import ALevelCIEEnglishLang from "./ALevelCIEEnglishLang";
import OCRPsych from "./OCRPsych";
import AQASoc from "./AQASoc";

import OCRGeogALevel from "./OCRGeogALevel";
import OCRSociologyALevel from "./OCRSociologyALevel";
import EdexHistoryIGCSE from "./EdexHistoryIGCSE";

import AQAPhilosophy from "./AQAPhilosophy";
import edexcelpsych from "./edexcelpsych";
import CIEHist from "./CIEHist";

import EdexcelGeographyGCSE from "./EdexcelGeographyGCSE";

import EduqasHistoryGCSE from "./EduqasHistoryGCSE";
import AQAPsych from "./AQAPsych";

import EdexcelEnglishGCSENonI from "./EdexcelEnglishGCSENonI";

import IELTS from "./IELTS";
import hkdse from "./hkdse";
import oet from "./oet";

import Pareto from "./Pareto";
import Eaton from "./Eaton";

import EmailVerification from "./EmailVerification";

import Cookies from "js-cookie";

import "./App.scss";

if (!window.store) {
  window.store = new AppStore();
}

@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({ showModal: !prevState.showModal }));
  };

  componentDidMount() {
    const data = {
      token: Cookies.get("token"),
      profile: Cookies.get("profile"),
    };

    if (data.profile && data.token) {
      window.store.loginWithDataTokenAndProfile(data);
    }
  }

  render() {
    const { showModal } = this.state;

    return (
      <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
            {window.store.redirect ? <Redirect to={window.store.redirect} /> : null}
            {window.store.isLoggedIn ? (
              <>
                {window.store.profile.status ? (
                  // Logged in with plan
                  <>
                    <Switch>
                      <Route path="/writing/document">
                        <div />
                      </Route>
                      <Route component={Header} />
                    </Switch>

                    <Switch>
                      <Route path="/" exact component={Dashboard} />
                      <Route path="/dashboard" exact component={Dashboard} />
                      <Route path="/search" exact component={Search} />
                      <Route path="/ai/" render={(props) => <Tool {...props} showModal={showModal} toggleModal={this.toggleModal} />} />

                      <Route path="/my-profile" component={Profile} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route path="/login/success" component={LoginSuccess} />
                      <Route path="/verify" exact component={Verification} />
                      <Route path="/payment-details" exact component={PaymentDetails} />
                      <Route path="/handwriting" exact component={Handwriting} />
                      <Route path="/handwritingtool" exact component={HandwritingTool} />
                      <Route path="/writing/handwritingtool" exact component={HandwritingTool} />
                      <Route path="/aqa-english" exact component={AQAEnglishGCSE} />
                      <Route path="/cantab-english" exact component={CantabEnglishGCSE} />
                      <Route path="/edexcel-english" exact component={EdexcelEnglishGCSE} />
                      <Route path="/edexcel-history" exact component={EdexcelHistoryGCSE} />
                      <Route path="/edexcel-rs" exact component={EdexcelRSGCSE} />
                      <Route path="/aqa-rs" exact component={AQARSGCSE} />
                      <Route path="/eduqas-english" exact component={EduqasEnglishGCSE} />
                      <Route path="/edexcel-economics" exact component={EdexcelEconomics} />
                      <Route path="/ks3-language" exact component={KS3EngLang} />
                      <Route path="/english-edexcel" exact component={EdexcelEnglishGCSENonI} />
                      <Route path="/edu-history" exact component={EduqasHistoryGCSE} />
                      <Route path="/spectator-two" exact component={SpectatorL2} />
                      <Route path="/oet" exact component={oet} />
                      <Route path="/email-verification" exact component={EmailVerification} />
                      <Route path="/english-aqa" exact component={ALevelAQAEnglish} />
                      <Route path="/ib-english" exact component={IBEnglish} />

                      <Route path="/cie-soc" exact component={CIESoc} />

                      <Route path="/cie-english" exact component={ALevelCIEEnglishLang} />

                      <Route path="/edex-geog" exact component={EdexcelGeographyGCSE} />

                      <Route path="/edex-geogi" exact component={EdexcelGeographyIGCSE} />

                      <Route path="/pol-edex" exact component={EdexcelPol} />

                      <Route path="/geog-edex" exact component={EdexGeogALevel} />

                      <Route path="/ib-englishlang" exact component={IBEnglishLang} />

                      <Route path="/wjec-english" exact component={WJECEnglish} />
                      <Route path="/psych-ocr" exact component={OCRPsych} />

                      <Route path="/soc-aqa" exact component={AQASoc} />
                      <Route path="/aqa-drama" exact component={AQADrama} />

                      <Route path="/aqa-geog" exact component={AQAGeographyGCSE} />
                      <Route path="/aqa-bus" exact component={AQABusinessALevel} />
                      <Route path="/geog-ocr" exact component={OCRGeogALevel} />

                      <Route path="/edexcel-ihistory" exact component={EdexHistoryIGCSE} />

                      <Route path="/soc-ocr" exact component={OCRSociologyALevel} />
                      <Route path="/cie-hist" exact component={CIEHist} />

                      <Route path="/ib-history" exact component={IBHistory} />
                      <Route path="/eng-myp" exact component={IBEnglishMYP} />
                      <Route path="/aqa-history" exact component={AQAHistoryGCSE} />
                      <Route path="/history" exact component={UserHistory} />
                      <Route path="/global-pol" exact component={IBGlobal} />
                      <Route path="/students" exact component={UserStudents} />
                      <Route path="/ocr-english" exact component={OCREnglishGCSE} />
                      <Route path="/ocr-rs" exact component={OCRReligiousStudiesGCSE} />
                      <Route path="/english-ocr" exact component={OCREnglishALevel} />
                      <Route path="/history-ocr" exact component={OCRHistoryALevel} />
                      <Route path="/edex-english" exact component={EdexcelEnglishALevel} />
                      <Route path="/ocr-history" exact component={OCRHistoryGCSE} />
                      <Route path="/aqa-philosophy" exact component={AQAPhilosophy} />
                      <Route path="/ocr-economics" exact component={OCREconomicsGCSE} />
                      <Route path="/english-eduqas" exact component={EduqasEnglishALevel} />
                      <Route path="/english-ccea" exact component={CCEAEnglishGCSE} />
                      <Route path="/english-ks2" exact component={KS2EngLang} />
                      <Route path="/cantab-history" exact component={CantabHistoryGCSE} />
                      <Route path="/psych-edex" exact component={edexcelpsych} />
                      <Route path="/ielts" exact component={IELTS} />
                      <Route path="/hkdse" exact component={hkdse} />
                      <Route path="/history-edexcel" exact component={EdexcelHistoryA} />
                      <Route path="/psych-aqa" exact component={AQAPsych} />

                      <Route path="/bus-edex" exact component={EdexBusinessALevel} />

                      <Route path="/edex-bus" exact component={EdexBusinessGCSE} />

                      <Route path="/pareto" exact component={Pareto} />
                      <Route path="/eaton" exact component={Eaton} />
                      <Route path="/writing/:id" component={ToolDynamic} />
                      <Route path="/writing/:id(hand)" component={ToolDynamic} />
                      <Route path="/writing/:id(batch)" component={ToolDynamic} />
                      <Route path="/writing/:id(word)" component={ToolDynamic} />
                      <Route path="/writing/:id(zip)" component={ToolDynamic} />
                    </Switch>
                    <HandwritingModal
                      config={handwritingConfig}
                      showModal={showModal}
                      closeModal={this.toggleModal}
                      // Add this line
                      onEntered={() => console.log("config", handwritingConfig)}
                    />
                  </>
                ) : (
                  // Logged in but no plan
                  <>
                    <Switch>
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route>
                        <Pricing />
                      </Route>
                    </Switch>
                  </>
                )}
              </>
            ) : (
              // Not logged in
              <>
                <Switch>
                  <Route path="/" exact>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/forgotPassword" exact>
                    <ForgotPassword />
                  </Route>
                  <Route path="/reset-password" exact>
                    <ResetPassword />
                  </Route>
                  <Route path="/" component={Login} />
                </Switch>
              </>
            )}
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
