import React, { Component } from "react";
import { Link } from "react-router-dom";
import { computed, observable, action, makeObservable } from "mobx";
import { observer, inject } from "mobx-react";
import { Helmet } from "react-helmet";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import MainBody from "./Components/Body";
import GCSESection from "./GCSESection";
import ALevelSection from "./ALevelSection";
import IBSection from "./IBSection";
import KS3Section from "./KS3Section";
import IELTSSection from "./IELTSSection";
import NCFESection from "./NCFESection";
import EdexcelSection from "./EdexcelSection";
import AQASection from "./AQASection";
import OCRSection from "./EdexcelSection";
import CIESection from "./EdexcelSection";
import { Switch } from "@headlessui/react";
import "./Dashboard.css";

const defaultSections = [
  "GCSE & IGCSE Marking Tools",
  "A Level Marking Tools",
  "International Baccalaureate Marking Tools",
  "KS2 & KS3 Marking Tools",
  "IELTS & HKDSE & OET",
  "NCFE Marking Tools",
];

// ExplainerVideosButton component
const ExplainerVideosButton = ({ onClick, isExpanded }) => {
  return (
    <button
      onClick={onClick}
      className={`fixed bottom-4 left-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 ${
        isExpanded ? "rounded-b-none" : ""
      }`}
    >
      Explainer Videos {isExpanded ? "▲" : "▼"}
    </button>
  );
};

// VideoButton component
const VideoButton = ({ onClick, title, index }) => {
  return (
    <button
      onClick={onClick}
      className={`fixed left-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 shadow-lg transition-all duration-300 ease-in-out transform hover:scale-105 ${
        index === 0 ? "bottom-16 rounded-t-none rounded-b" : `bottom-${24 + index * 8} rounded-none`
      }`}
    >
      {title}
    </button>
  );
};

// VideoModal component
const VideoModal = ({ isOpen, onClose, videoUrl, title }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-lg w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-black hover:text-gray-700">
            &times;
          </button>
        </div>
        <div className="relative" style={{ paddingBottom: "56.25%" }}>
          <iframe src={videoUrl} className="absolute top-0 left-0 w-full h-full" frameBorder="0" allowFullScreen></iframe>
        </div>
      </div>
    </div>
  );
};

@inject("store")
@observer
class Body extends Component {
  @observable selectedSections = [];
  @observable isVideoModalOpen = false;
  @observable currentVideoUrl = "";
  @observable currentVideoTitle = "";
  @observable isExplainerVideosExpanded = false;
  @observable sortBySubject = false;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.selectedSections = JSON.parse(localStorage.getItem("selectedSections")) || defaultSections;
  }

  @action handleSectionToggle = (section) => {
    if (this.selectedSections.includes(section)) {
      this.selectedSections = this.selectedSections.filter((s) => s !== section);
    } else {
      this.selectedSections.push(section);
    }
    localStorage.setItem("selectedSections", JSON.stringify(this.selectedSections));
  };

  @action handleDragEnd = (result) => {
    if (!result.destination) return;

    const newSelectedSections = Array.from(this.selectedSections);
    const [reorderedSection] = newSelectedSections.splice(result.source.index, 1);
    newSelectedSections.splice(result.destination.index, 0, reorderedSection);

    this.selectedSections = newSelectedSections;
    localStorage.setItem("selectedSections", JSON.stringify(this.selectedSections));
  };

  @computed get hiddenSections() {
    return defaultSections.filter((section) => !this.selectedSections.includes(section));
  }

  renderSection = (section) => {
    const { permissions } = this.props.store;

    switch (section) {
      case "GCSE & IGCSE Marking Tools":
        return <GCSESection key={section} permissions={permissions} />;
      case "A Level Marking Tools":
        return <ALevelSection key={section} permissions={permissions} />;
      case "International Baccalaureate Marking Tools":
        return <IBSection key={section} permissions={permissions} />;
      case "KS2 & KS3 Marking Tools":
        return <KS3Section key={section} permissions={permissions} />;
      case "IELTS & HKDSE & OET":
        return <IELTSSection key={section} permissions={permissions} />;
      case "NCFE Marking Tools":
        return <NCFESection key={section} permissions={permissions} />;
      default:
        return null;
    }
  };

  @action toggleExplainerVideos = () => {
    this.isExplainerVideosExpanded = !this.isExplainerVideosExpanded;
  };

  @action openVideoModal = (url, title) => {
    this.currentVideoUrl = url;
    this.currentVideoTitle = title;
    this.isVideoModalOpen = true;
  };

  @action closeVideoModal = () => {
    this.isVideoModalOpen = false;
    this.currentVideoUrl = "";
    this.currentVideoTitle = "";
  };

  @action toggleSortMethod = () => {
    this.sortBySubject = !this.sortBySubject;
  };

  renderSortedSections = () => {
    if (this.sortBySubject) {
      return (
        <>
          <div className="mb-6 bg-blue-50 border-l-4 border-blue-500 p-4 rounded-md shadow-sm">
            <p className="text-blue-700 font-medium">
              <span className="mr-2">💡</span>
              Pro Tip: You can reorder tools within each section using drag and drop!
            </p>
          </div>
          <EdexcelSection permissions={this.props.store.permissions} />
          <AQASection permissions={this.props.store.permissions} />
          <OCRSection permissions={this.props.store.permissions} />
          <CIESection permissions={this.props.store.permissions} />
          {/* Add more subject sections as needed */}
        </>
      );
    } else {
      return (
        <>
          <div className="mb-6 bg-blue-50 border-l-4 border-blue-500 p-4 rounded-md shadow-sm">
            <p className="text-blue-700 font-medium">
              <span className="mr-2">💡</span>
              Pro Tip: You can reorder tools within each section using drag and drop!
            </p>
          </div>
          {this.selectedSections.map(this.renderSection)}
        </>
      );
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{`Tools - Exam Paper Autopilot`}</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12 flex main-body">
          <div className="left-panel">
            <h3 className="text-xl font-bold mb-4">Organise Your Marking Tools</h3>
            <div className="mb-4"></div>
            {!this.sortBySubject && (
              <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId="section-toggles">
                  {(provided) => (
                    <div className="section-toggles" {...provided.droppableProps} ref={provided.innerRef}>
                      {this.selectedSections.map((section, index) => (
                        <Draggable key={section} draggableId={section} index={index}>
                          {(provided) => (
                            <div
                              className="section-toggle"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <input
                                type="checkbox"
                                checked={this.selectedSections.includes(section)}
                                onChange={() => this.handleSectionToggle(section)}
                              />
                              <label>{section}</label>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
            {!this.sortBySubject && (
              <div className="hidden-sections">
                {this.hiddenSections.map((section) => (
                  <div key={section} className="section-toggle">
                    <input
                      type="checkbox"
                      checked={this.selectedSections.includes(section)}
                      onChange={() => this.handleSectionToggle(section)}
                    />
                    <label>{section}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="main-content">
            <TransitionGroup>{this.renderSortedSections()}</TransitionGroup>
          </div>
        </MainBody>
        <ExplainerVideosButton onClick={this.toggleExplainerVideos} isExpanded={this.isExplainerVideosExpanded} />
        {this.isExplainerVideosExpanded && (
          <>
            <VideoButton
              onClick={() =>
                this.openVideoModal(
                  "https://accoladetuition.hippovideo.io/video/embed/EGimLZqqDpkbTsPp374bGuMwwIVi6w_iiCCirwai8Cc?org_tok=op2ne08u01xuO_ve6yU_iQ&autoplay=false",
                  "Instructional Video for Batch Marking"
                )
              }
              title="Batch Marking: Single PDF"
              index={0}
            />
            <VideoButton
              onClick={() =>
                this.openVideoModal(
                  "https://accoladetuition.hippovideo.io/video/embed/Y0CeaBYRryp70ex1_Z6gby-vs59SPfg8wgXWw3Q2FK0?org_tok=op2ne08u01xuO_ve6yU_iQ&autoplay=false",
                  "Marking Questions with Visual Stimuli"
                )
              }
              title="Visual Stimuli Questions"
              index={1}
            />
          </>
        )}
        <VideoModal
          isOpen={this.isVideoModalOpen}
          onClose={this.closeVideoModal}
          videoUrl={this.currentVideoUrl}
          title={this.currentVideoTitle}
        />
      </>
    );
  }
}

export const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">{children}</div>;

export const Tool = ({ Icon, title, desc, to, group, fromColor, toColor }) => (
  <Link to={to || "/"} className="flex relative ">
    <div
      className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105  hover:text-black`}
    >
      <div className="p-4">
        <div className={`uppercase tracking-wide text-sm text-${fromColor ? fromColor : "green-500"} font-semibold leading-none`}>
          {group || "New"}
        </div>
        <div href="#" className="block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none">
          {title}
        </div>
        <p className="mt-1 pr-1 text-sm ">{desc} </p>
      </div>
    </div>
  </Link>
);

export default Body;
