import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer, inject } from "mobx-react";
import MainBody from "./Components/Body";
import { Helmet } from "react-helmet";
import "./Style.css";

// Import the handwriting image
import handwriting from "./handwriting.png";

@inject("store")
@observer
class Body extends Component {
  state = {
    tools: [],
    loading: true,
  };

  async componentDidMount() {
    try {
      const response = await fetch("https://app.topmarks.ai/api/user/tools/histedex", {
        headers: {
          Authorization: `Bearer ${this.props.store.jwtToken}`,
        },
      });
      const fetchedTools = await response.json();

      // Add the static handwriting tool
      const handwritingTool = {
        _id: "handwritingtool",
        title: "Turn Handwritten Scripts into Text!",
        category: "Experimental",
        description: "Use this tool to convert PDFs of handwritten scripts into fully editable text (that we can then mark!).",
        fromColor: "blue-400",
        toColor: "blue-600",
        image: "handwriting.png",
      };

      // Combine fetched tools with the static tool
      const allTools = [handwritingTool, ...fetchedTools];

      this.setState({ tools: allTools, loading: false });
    } catch (error) {
      console.error("Failed to fetch tools:", error);
      this.setState({ loading: false });
    }
  }

  render() {
    const { tools, loading } = this.state;

    if (loading) {
      return <div>Loading...</div>;
    }

    // Separate the handwriting tool from other tools
    const handwritingTool = tools.find((tool) => tool._id === "handwritingtool");
    const otherTools = tools.filter((tool) => tool._id !== "handwritingtool");

    return (
      <>
        <Helmet>
          <title>{`Tools - Exam Paper Autopilot`}</title>
        </Helmet>
        <MainBody className="px-4 py-4 md:px-28 md:py-8 lg:py-12">
          {handwritingTool && (
            <>
              <Title
                title={
                  <p>
                    <b>Turn Handwritten Work into Editable Text</b>
                  </p>
                }
              />
              <Grid>
                <div className="tool-container">
                  <img src={handwriting} alt={handwritingTool.title} className="tool-image" />
                  <Tool
                    category={handwritingTool.category}
                    title={handwritingTool.title}
                    to={handwritingTool._id}
                    desc={handwritingTool.description}
                    fromColor={handwritingTool.fromColor}
                    toColor={handwritingTool.toColor}
                  />
                </div>
              </Grid>
              <Divider />
            </>
          )}

          {otherTools.length ? (
            <>
              <Title
                title={
                  <p>
                    <b>A Level History</b>: Edexcel A Level Marking Assistants
                  </p>
                }
              />
              <Grid>
                {otherTools.map((tool, index) => (
                  <div key={index} className="tool-container">
                    <img src={`/${tool.image || "default.png"}`} alt={tool.title} className="tool-image" />{" "}
                    <Tool
                      key={index}
                      category={tool.category}
                      title={tool.title}
                      to={tool._id}
                      desc={tool.description}
                      fromColor={tool.fromColor}
                      toColor={tool.toColor}
                      isHandwritingTool={false}
                    />
                  </div>
                ))}
              </Grid>
              <Divider />
            </>
          ) : (
            <div>No tools available</div>
          )}
        </MainBody>
      </>
    );
  }
}

export const Divider = () => (
  <div className="divide-y-2 divide-dashed divide-gray-300 py-8 md:py-12">
    <div></div>
    <div></div>
  </div>
);

export const Title = ({ title }) => <h2 className="text-xl sm:text-2xl md:text-3xl text-gray-700 mb-4 md:mb-6">{title}</h2>;

export const Grid = ({ children }) => <div className="grid grid-cols-1 gap-8 mt-4 lg:grid-cols-2 xl:grid-cols-3 ">{children}</div>;

export const Tool = ({ category, title, desc, to, fromColor, toColor, isHandwritingTool }) => {
  console.log("Tool ID in Tool component:", to);
  return (
    <Link to={`/writing/${to}`} className="flex relative ">
      <div
        className={`bg-white flex-1 rounded-xl transition hover:shadow-md overflow-hidden md:max-w-1lg text-gray-500 cursor-pointer border border-gray-300 md:flex relative transform hover:scale-105 hover:text-black`}
      >
        <div className="p-4">
          <div
            className={`uppercase tracking-wide text-sm ${
              isHandwritingTool ? `text-${fromColor}` : "text-pink-500"
            } font-semibold leading-none`}
          >
            {category}
          </div>
          <div className={`block text-lg xl:text-xl 2xl:text-2xl leading-tight font-medium text-black leading-none`}>{title}</div>
          <p className="mt-1 pr-1 text-sm">{desc}</p>
        </div>
      </div>
    </Link>
  );
};

export default Body;
