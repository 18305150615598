import React from "react";
import { GlobeIcon } from "@heroicons/react/outline";

const CurrencyButton = ({ currency, isSelected, onClick }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ease-in-out ${
      isSelected ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-700 hover:bg-gray-300"
    }`}
  >
    {currency === "GBP" ? "£ GBP" : "$ USD"}
  </button>
);

const CurrencyToggle = ({ currency, setCurrency }) => (
  <div className="flex items-center justify-end mb-6">
    <GlobeIcon className="h-5 w-5 mr-3 text-gray-500" />
    <div className="bg-gray-100 p-1 rounded-lg flex space-x-1">
      <CurrencyButton currency="GBP" isSelected={currency === "GBP"} onClick={() => setCurrency("GBP")} />
      <CurrencyButton currency="USD" isSelected={currency === "USD"} onClick={() => setCurrency("USD")} />
    </div>
  </div>
);

export default CurrencyToggle;
