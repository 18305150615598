import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import moment from "moment";
import Modal from "react-modal";
import { DocumentTextIcon, CogIcon, FolderIcon, InformationCircleIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { saveAs } from "file-saver";
import "./UserHistory.css";
import { TailSpin } from "react-loader-spinner";

@inject("store")
@observer
class UserHistory extends Component {
  state = {
    history: [],
    groupedHistory: {},
    folders: [],
    loading: true,
    selectedHistory: null,
    modalIsOpen: false,
    folderModalIsOpen: false,
    renameModalIsOpen: false,
    newFolderName: "",
    draggedItem: null,
    selectedFolder: null,
    folderToRename: null,
    newFolderName: "",
    multiSelectMode: false,
    selectedItems: [],
    editingHistoryItem: null,
    newLabel: "",
    showConfirmationModal: false,
    historyToDelete: null,
    folderItemsOrder: {},
    currentHistoryIndex: 0,
  };

  debugLog(message, data) {
    console.log(`[DEBUG] ${message}`, data);
  }

  colorGradient(index, total) {
    const minOpacity = 0.2;
    const maxOpacity = 0.5;
    const opacityRange = maxOpacity - minOpacity;
    const fraction = ((total - index) / total) * opacityRange + minOpacity;
    const lightBlue = [68, 108, 179];
    return `rgba(${lightBlue.join(",")}, ${fraction})`;
  }

  parseInput(inputText) {
    const parts = {};
    const lines = inputText.split("\n");
    let currentKey = "";

    lines.forEach((line) => {
      if (line.includes(":")) {
        const [key, ...value] = line.split(":");
        currentKey = key.trim();
        parts[currentKey] = value.join(":").trim();
      } else if (currentKey) {
        parts[currentKey] += "\n" + line.trim();
      }
    });

    return parts;
  }

  componentDidMount() {
    const url = "https://app.topmarks.ai/api/user/fetch-user-history";
    const jwtToken = this.props.store.jwtToken;

    if (typeof jwtToken !== "string" || jwtToken === "") {
      console.error("jwtToken is missing or not a string in the store:", jwtToken);
      this.setState({ loading: false });
      return;
    }

    const headers = { Authorization: `Bearer ${jwtToken}` };

    fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return response.text().then((text) => {
          let errorMessage = "Error: ";
          switch (response.status) {
            case 401:
              errorMessage += "Unauthorized";
              break;
            case 403:
              errorMessage += "Forbidden";
              break;
            case 404:
              errorMessage += "Not Found";
              break;
            default:
              errorMessage += `Unknown (${response.status})`;
          }
          throw new Error(errorMessage);
        });
      })
      .then((data) => {
        const filteredHistory = data.userHistory.filter((item) => {
          const itemDate = moment(item.created);
          const filterDate = moment("July 2023", "MMMM YYYY");
          return itemDate.isSameOrAfter(filterDate) && !item.forgotten;
        });

        this.setState({ history: filteredHistory, loading: false }, this.groupHistoryByMonth);
        this.fetchFolders();
      })
      .catch((error) => {
        console.error("Failed to fetch user history:", error);
        this.setState({ loading: false });
      });
  }

  fetchFolders = () => {
    const url = "https://app.topmarks.ai/api/user/fetch-folders";
    const jwtToken = this.props.store.jwtToken;
    const headers = { Authorization: `Bearer ${jwtToken}` };

    fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ folders: data.folders });
      })
      .catch((error) => {
        console.error("Failed to fetch folders:", error);
      });
  };

  toggleMultiSelect = () => {
    this.setState((prevState) => ({
      multiSelectMode: !prevState.multiSelectMode,
      selectedItems: [],
      selectedFolder: null, // Clear selected folder when toggling multi-select
      folderModalIsOpen: false, // Close folder modal if open
    }));
  };

  createFolder = () => {
    const { newFolderName } = this.state;
    const url = "https://app.topmarks.ai/api/user/create-folder";
    const jwtToken = this.props.store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ name: newFolderName });

    fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const newFolder = {
          _id: data.folderId,
          name: newFolderName,
          items: [],
        };
        this.setState((prevState) => ({
          folders: [...prevState.folders, newFolder],
          newFolderName: "",
        }));
      })
      .catch((error) => {
        console.error("Failed to create folder:", error);
      });
  };

  updateFolder = (folderId, historyItem) => {
    const url = "https://app.topmarks.ai/api/user/update-folder";
    const jwtToken = this.props.store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ folderId, historyItem });

    fetch(url, {
      method: "PUT",
      headers: headers,
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        this.fetchFolders();
        this.setState((prevState) => ({
          folders: prevState.folders.map((folder) => {
            if (folder._id === folderId) {
              return {
                ...folder,
                items: [...folder.items, historyItem],
              };
            }
            return folder;
          }),
          history: prevState.history.filter((item) => item._id !== historyItem._id),
        }));
      })
      .catch((error) => {
        console.error("Failed to update folder:", error);
      });
  };

  removeFromFolder = async (folderId, historyItemId) => {
    const url = "https://app.topmarks.ai/api/user/remove-from-folder";
    const jwtToken = this.props.store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ folderId, historyItemId });

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      this.setState((prevState) => ({
        folders: prevState.folders.map((folder) => {
          if (folder._id === folderId) {
            return {
              ...folder,
              items: folder.items.filter((itemId) => itemId !== historyItemId),
            };
          }
          return folder;
        }),
        selectedFolder:
          prevState.selectedFolder && prevState.selectedFolder._id === folderId
            ? {
                ...prevState.selectedFolder,
                items: prevState.selectedFolder.items.filter((itemId) => itemId !== historyItemId),
              }
            : prevState.selectedFolder,
      }));
    } catch (error) {
      console.error("Failed to remove item from folder:", error);
    }
  };

  deleteFolder = (folderId) => {
    const url = `https://app.topmarks.ai/api/user/delete-folder/${folderId}`;
    const jwtToken = this.props.store.jwtToken;
    const headers = { Authorization: `Bearer ${jwtToken}` };

    fetch(url, {
      method: "DELETE",
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(() => {
        this.fetchFolders();
        this.fetchUserHistory();
      })
      .catch((error) => {
        console.error("Failed to delete folder:", error);
      });
  };

  renameFolder = (folderId, newName) => {
    const url = `https://app.topmarks.ai/api/user/rename-folder/${folderId}`;
    const jwtToken = this.props.store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ newName });

    fetch(url, {
      method: "PUT",
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        this.fetchFolders();
      })
      .catch((error) => {
        console.error("Failed to rename folder:", error);
      });
  };

  downloadAsWord = () => {
    const { selectedHistory } = this.state;

    let input = selectedHistory.input;
    const questionIndex = input.indexOf("QUESTION");
    if (questionIndex !== -1) {
      input = input.slice(questionIndex);
    }

    fetch("https://app.topmarks.ai/api/user/download-word", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        input: input,
        output: selectedHistory.output,
      }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "history.docx";
        a.click();
      });
  };

  downloadFolder = async (folderId) => {
    const url = "https://app.topmarks.ai/api/user/download-folder";
    const jwtToken = this.props.store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ folderId });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      saveAs(blob, `${folderId}.zip`);
    } catch (error) {
      console.error("Failed to download folder:", error);
    }
  };

  groupHistoryByMonth() {
    const groupedHistory = this.state.history.reduce((acc, item) => {
      const monthYearKey = moment(item.created).format("MMMM YYYY");
      if (!acc[monthYearKey]) {
        acc[monthYearKey] = [];
      }
      acc[monthYearKey].push(item);
      return acc;
    }, {});

    for (let month in groupedHistory) {
      groupedHistory[month].sort((a, b) => moment(b.created).toDate() - moment(a.created).toDate());
    }

    this.setState({ groupedHistory });
  }

  handleLabelEdit = (historyItem) => {
    this.setState({
      editingHistoryItem: historyItem,
      newLabel: historyItem.label || "",
    });
  };

  handleLabelChange = (event) => {
    this.setState({ newLabel: event.target.value });
  };

  openConfirmationModal = (historyItemId) => {
    this.setState({
      showConfirmationModal: true,
      historyToDelete: historyItemId,
    });
  };

  closeConfirmationModal = () => {
    this.setState({
      showConfirmationModal: false,
      historyToDelete: null,
    });
  };

  confirmDeleteHistory = () => {
    const { historyToDelete } = this.state;
    if (historyToDelete) {
      this.handleDeleteHistory(historyToDelete);
      this.closeConfirmationModal();
    }
  };

  handleDeleteHistory = async (historyItemId) => {
    const url = `https://app.topmarks.ai/api/user/delete-history/${historyItemId}`;
    const jwtToken = this.props.store.jwtToken;
    const headers = { Authorization: `Bearer ${jwtToken}` };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      this.setState((prevState) => {
        const updatedHistory = prevState.history.filter((item) => item._id !== historyItemId);
        const updatedGroupedHistory = {};

        Object.keys(prevState.groupedHistory).forEach((monthYear) => {
          updatedGroupedHistory[monthYear] = prevState.groupedHistory[monthYear].filter((item) => item._id !== historyItemId);
        });

        return {
          history: updatedHistory,
          groupedHistory: updatedGroupedHistory,
        };
      });
    } catch (error) {
      console.error("Failed to delete history item:", error);
    }
  };

  saveLabel = async () => {
    const { editingHistoryItem, newLabel, selectedFolder, selectedHistory } = this.state;
    if (editingHistoryItem && newLabel !== editingHistoryItem.label) {
      const url = "https://app.topmarks.ai/api/user/update-history-label";
      const jwtToken = this.props.store.jwtToken;
      const headers = {
        Authorization: `Bearer ${jwtToken}`,
        "Content-Type": "application/json",
      };
      const body = JSON.stringify({
        historyItemId: editingHistoryItem._id,
        newLabel: newLabel,
      });

      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: headers,
          body: body,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        this.setState((prevState) => {
          const updatedHistory = prevState.history.map((item) =>
            item._id === editingHistoryItem._id ? { ...item, label: newLabel } : item
          );

          const updatedGroupedHistory = { ...prevState.groupedHistory };
          Object.keys(updatedGroupedHistory).forEach((monthYear) => {
            updatedGroupedHistory[monthYear] = updatedGroupedHistory[monthYear].map((item) =>
              item._id === editingHistoryItem._id ? { ...item, label: newLabel } : item
            );
          });

          const updatedSelectedFolder = selectedFolder
            ? {
                ...selectedFolder,
                items: selectedFolder.items.map((item) => (item._id === editingHistoryItem._id ? { ...item, label: newLabel } : item)),
              }
            : null;

          const updatedSelectedHistory =
            selectedHistory && selectedHistory._id === editingHistoryItem._id ? { ...selectedHistory, label: newLabel } : selectedHistory;

          return {
            history: updatedHistory,
            groupedHistory: updatedGroupedHistory,
            selectedFolder: updatedSelectedFolder,
            selectedHistory: updatedSelectedHistory,
            editingHistoryItem: null,
            newLabel: "",
          };
        });

        if (selectedFolder) {
          this.fetchFolders();
        }
      } catch (error) {
        console.error("Failed to update history label:", error);
      }
    }
  };
  handleHistoryItemClick = (historyItem, event) => {
    const { multiSelectMode, selectedItems, selectedFolder } = this.state;
    const targetTag = event.target.tagName;

    if (multiSelectMode && !selectedFolder) {
      event.stopPropagation();
      this.setState((prevState) => ({
        selectedItems: prevState.selectedItems.includes(historyItem._id)
          ? prevState.selectedItems.filter((id) => id !== historyItem._id)
          : [...prevState.selectedItems, historyItem._id],
      }));
    } else if (targetTag !== "INPUT" && targetTag !== "BUTTON") {
      const currentHistoryIndex = selectedFolder ? selectedFolder.items.findIndex((item) => item._id === historyItem._id) : -1;
      this.setState({
        selectedHistory: historyItem,
        modalIsOpen: true,
        folderModalIsOpen: false,
        currentHistoryIndex: currentHistoryIndex,
      });
    }
  };

  handleFolderClick = (folder) => {
    this.setState({
      selectedFolder: folder,
      folderModalIsOpen: true,
      multiSelectMode: false,
    });
  };

  closeFolderModal = () => {
    this.setState({ folderModalIsOpen: false });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  handleFolderNameChange = (event) => {
    this.setState({ newFolderName: event.target.value });
  };

  handleDragStart = (e, item, source) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ item, source }));
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  handleDrop = (e, targetId, dropTarget) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text"));
    const { item, source } = data;

    if (dropTarget === "folder") {
      if (this.state.multiSelectMode && this.state.selectedItems.length > 0) {
        this.handleDropIntoFolder(this.state.selectedItems, targetId);
      } else {
        this.handleDropIntoFolder([item], targetId);
      }
    } else if (dropTarget === "folderItem" && source === "folderItem") {
      this.reorderFolderItems(item, targetId);
    }
  };
  handleDropIntoFolder = (itemIds, folderId) => {
    const { history, groupedHistory, folders } = this.state;
    const itemsToAdd = Array.isArray(itemIds) ? itemIds : [itemIds];

    this.debugLog("Drop event", { folderId, itemsToAdd });

    const updatedFolders = folders.map((folder) => {
      if (folder._id === folderId) {
        const newItems = itemsToAdd.map((itemId) => history.find((item) => item._id === itemId)).filter(Boolean);
        return {
          ...folder,
          items: [...folder.items, ...newItems],
          isHighlighted: true,
        };
      }
      return folder;
    });

    const updatedHistory = history.filter((item) => !itemsToAdd.includes(item._id));
    const updatedGroupedHistory = { ...groupedHistory };

    Object.keys(updatedGroupedHistory).forEach((monthYear) => {
      updatedGroupedHistory[monthYear] = updatedGroupedHistory[monthYear].filter((item) => !itemsToAdd.includes(item._id));
      if (updatedGroupedHistory[monthYear].length === 0) {
        delete updatedGroupedHistory[monthYear];
      }
    });

    this.setState(
      {
        folders: updatedFolders,
        history: updatedHistory,
        groupedHistory: updatedGroupedHistory,
        draggedItem: null,
        selectedItems: [],
      },
      () => {
        setTimeout(() => {
          this.setState((prevState) => ({
            folders: prevState.folders.map((folder) => ({ ...folder, isHighlighted: false })),
          }));
        }, 500);

        itemsToAdd.forEach((itemId) => {
          const historyItem = history.find((item) => item._id === itemId);
          if (historyItem) {
            this.debugLog("Updating folder for item", { folderId, historyItem });
            this.updateFolder(folderId, historyItem);
          }
        });
      }
    );
  };

  reorderFolderItems = (oldIndex, newIndex) => {
    const { selectedFolder } = this.state;
    if (!selectedFolder) return;

    const newItems = Array.from(selectedFolder.items);
    const [reorderedItem] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, reorderedItem);

    this.setState(
      (prevState) => ({
        selectedFolder: {
          ...prevState.selectedFolder,
          items: newItems,
        },
        folderItemsOrder: {
          ...prevState.folderItemsOrder,
          [selectedFolder._id]: newItems.map((item) => item._id),
        },
      }),
      () =>
        this.updateFolderOrderOnServer(
          selectedFolder._id,
          newItems.map((item) => item._id)
        )
    );
  };

  updateFolderOrderOnServer = (folderId, newOrder) => {
    const url = "https://app.topmarks.ai/api/user/update-folder-order";
    const jwtToken = this.props.store.jwtToken;
    const headers = {
      Authorization: `Bearer ${jwtToken}`,
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({ folderId, newOrder });

    fetch(url, {
      method: "PUT",
      headers: headers,
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Folder order updated successfully");
      })
      .catch((error) => {
        console.error("Failed to update folder order:", error);
      });
  };

  handleNextHistory = () => {
    const { selectedFolder, currentHistoryIndex } = this.state;
    if (selectedFolder && currentHistoryIndex < selectedFolder.items.length - 1) {
      this.setState((prevState) => ({
        currentHistoryIndex: prevState.currentHistoryIndex + 1,
        selectedHistory: selectedFolder.items[prevState.currentHistoryIndex + 1],
      }));
    }
  };

  handlePreviousHistory = () => {
    const { currentHistoryIndex } = this.state;
    if (currentHistoryIndex > 0) {
      this.setState((prevState) => ({
        currentHistoryIndex: prevState.currentHistoryIndex - 1,
        selectedHistory: prevState.selectedFolder.items[prevState.currentHistoryIndex - 1],
      }));
    }
  };

  renderHistoryDetails() {
    const { selectedHistory, modalIsOpen, editingHistoryItem, newLabel, selectedFolder, currentHistoryIndex } = this.state;
    if (!selectedHistory) {
      return null;
    }

    let input = selectedHistory.input;
    const questionIndex = input.indexOf("QUESTION");
    if (questionIndex !== -1) {
      input = input.slice(questionIndex);
    }

    const inputParts = this.parseInput(input);

    const bubbleStyle = {
      fontSize: "1em",
      color: "#000000",
      padding: "20px",
      borderRadius: "25px",
      backgroundColor: "white",
      boxShadow: "0 10px 15px rgba(0, 0, 0, 0.1)",
      margin: "20px 0",
      border: "1px solid rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      whiteSpace: "pre-wrap",
      transition: "all 0.5s ease",
    };

    const titleStyle = {
      fontSize: "1.2em",
      padding: "10px",
      borderRadius: "15px",
      boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
      margin: "10px 0",
      textAlign: "center",
      whiteSpace: "pre-wrap",
      transition: "all 0.5s ease",
      color: "white",
      background: "linear-gradient(to right, #000000, #434343)",
      fontFamily: "Arial, sans-serif",
      textShadow: "2px 2px 4px #000000",
    };

    const buttonStyle = {
      padding: "8px 16px",
      margin: "0 5px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      backgroundColor: "#f8f8f8",
      cursor: "pointer",
      transition: "all 0.3s ease",
    };

    const navigationButtonStyle = {
      ...buttonStyle,
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      padding: "8px 16px",
      margin: "0 5px",
      borderRadius: "4px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Selected History Details"
        style={{
          content: {
            width: "60%",
            margin: "0 auto",
          },
          overlay: {},
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" }}>
          <div>
            {editingHistoryItem === selectedHistory ? (
              <div>
                <input
                  type="text"
                  value={newLabel}
                  onChange={this.handleLabelChange}
                  onBlur={this.saveLabel}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      this.saveLabel();
                    }
                  }}
                  className="edit-label-input"
                />
                <button onClick={this.saveLabel} className="save-label-button">
                  Save
                </button>
              </div>
            ) : (
              <div>
                <span style={{ fontSize: "1.2em", fontWeight: "bold", marginRight: "10px" }}>
                  {selectedHistory.label || selectedHistory.api.split("/").pop()}
                </span>
                <button onClick={() => this.handleLabelEdit(selectedHistory)} className="edit-label-button">
                  Edit Label
                </button>
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {selectedFolder && selectedFolder.items.some((item) => item._id === selectedHistory._id) && (
              <>
                <button style={navigationButtonStyle} onClick={this.handlePreviousHistory} disabled={currentHistoryIndex === 0}>
                  <ChevronLeftIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                  Previous
                </button>
                <button
                  style={navigationButtonStyle}
                  onClick={this.handleNextHistory}
                  disabled={currentHistoryIndex === selectedFolder.items.length - 1}
                >
                  Next
                  <ChevronRightIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                </button>
              </>
            )}
            <button
              style={{
                ...buttonStyle,
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                marginLeft: "10px", // Add some space between navigation and download buttons
              }}
              onClick={this.downloadAsWord}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ marginRight: "10px" }}>Download to Word</p>
                <DocumentTextIcon className="h-7 w-7" aria-hidden="true" />
              </div>
            </button>
            {selectedFolder && (
              <button
                style={buttonStyle}
                onClick={() => {
                  this.closeModal();
                  this.setState({ folderModalIsOpen: true });
                }}
              >
                Back to Folder
              </button>
            )}
            <button style={buttonStyle} onClick={this.closeModal}>
              Close
            </button>
          </div>
        </div>

        {selectedHistory.tool !== "handwriting" && (
          <>
            <div style={titleStyle}>Question & Student Response</div>
            <div style={bubbleStyle}>
              <p>
                <strong>QUESTION:</strong> {inputParts.QUESTION}
              </p>
              <p>
                <strong>STUDENT:</strong>
                {inputParts.STUDENT.split("\n").map((paragraph, index) => (
                  <React.Fragment key={index}>
                    {paragraph}
                    <br />
                  </React.Fragment>
                ))}
              </p>
              {inputParts["IMAGE DESCRIPTION"] && (
                <p>
                  <strong>IMAGE DESCRIPTION:</strong> {inputParts["IMAGE DESCRIPTION"]}
                </p>
              )}
            </div>
          </>
        )}
        <div style={titleStyle}>Feedback</div>
        <div style={bubbleStyle} dangerouslySetInnerHTML={{ __html: selectedHistory.output }}></div>
      </Modal>
    );
  }

  renderFolderDetails() {
    const { selectedFolder, folderModalIsOpen } = this.state;
    if (!selectedFolder) return null;

    return (
      <Modal
        isOpen={folderModalIsOpen}
        onRequestClose={this.closeFolderModal}
        contentLabel="Folder Contents"
        style={{
          content: {
            width: "60%",
            margin: "0 auto",
          },
          overlay: {},
        }}
      >
        <button onClick={this.closeFolderModal}>Close</button>
        <h2 className="folder-modal-title">
          <span>{selectedFolder.name}</span>
        </h2>
        <button className="download-all-button" onClick={() => this.downloadFolder(selectedFolder._id)}>
          <DocumentTextIcon className="h-6 w-6 mr-2" aria-hidden="true" />
          Download All (ZIP file)
        </button>
        {selectedFolder.items.length === 0 ? (
          <p>No items in this folder.</p>
        ) : (
          selectedFolder.items.map((historyItem, idx) => (
            <div
              key={historyItem._id}
              className="folder-item"
              draggable
              onDragStart={(e) => this.handleDragStart(e, idx, "folderItem")}
              onDragOver={this.handleDragOver}
              onDrop={(e) => this.handleDrop(e, idx, "folderItem")}
              onClick={(event) => {
                event.stopPropagation();
                this.handleHistoryItemClick(historyItem, event);
              }}
            >
              <div className="item-details">
                <div>
                  <CogIcon className="icon h-6 w-6 inline-block mr-2" aria-hidden="true" />
                  {this.state.editingHistoryItem === historyItem ? (
                    <input
                      className="edit-label-input"
                      type="text"
                      value={this.state.newLabel}
                      onChange={this.handleLabelChange}
                      onBlur={this.saveLabel}
                      onClick={(e) => e.stopPropagation()}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.saveLabel();
                        }
                      }}
                    />
                  ) : (
                    <>
                      <p className="label inline-block">{historyItem.label || historyItem.api.split("/").pop()}</p>
                      <div className="actions">
                        <button
                          style={{
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            padding: "5px 10px",
                            marginLeft: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleLabelEdit(historyItem);
                          }}
                        >
                          Edit Label
                        </button>
                        <p className="date">{moment(historyItem.created).format("MMMM D")}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </Modal>
    );
  }

  renderLoad() {
    const loadingContainerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100%",
    };

    return (
      <div style={loadingContainerStyle}>
        <TailSpin height="80" width="80" color="#4fa94d" ariaLabel="tail-spin-loading" radius="1" visible={true} />
      </div>
    );
  }

  renderError() {
    return <h2>Error loading user histories</h2>;
  }

  renderNoHistory() {
    return <h2 className="no-history">No previous enquiries found</h2>;
  }

  renderHistory() {
    const { multiSelectMode, selectedItems } = this.state;
    const monthYears = Object.keys(this.state.groupedHistory).sort(
      (a, b) => moment(b, "MMMM YYYY").toDate() - moment(a, "MMMM YYYY").toDate()
    );

    if (!monthYears.length) {
      return <h2>No history available</h2>;
    }

    return (
      <div className="history-container">
        {monthYears.map((monthYear, index) => (
          <div key={monthYear}>
            <h3
              style={{
                fontSize: "1.5em",
                color: "#000000",
                padding: "10px 20px",
                borderRadius: "20px",
                backgroundColor: this.colorGradient(index, monthYears.length - 1),
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)",
                margin: "40px 0 10px 0",
                border: "1px solid #000000",
                display: "inline-block",
                textAlign: "center",
              }}
            >
              {monthYear}
            </h3>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {this.state.groupedHistory[monthYear].map((historyItem, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    margin: "10px",
                    cursor: "pointer",
                    borderRadius: "10px",
                    width: "18%",
                    textAlign: "center",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.15)",
                    transition: "all 0.3s ease-in-out",
                    wordWrap: "break-word",
                    opacity: multiSelectMode ? (selectedItems.includes(historyItem._id) ? 0.6 : 1) : 1,
                  }}
                  draggable
                  onDragStart={(event) => this.handleDragStart(event, historyItem._id, "history")}
                  onClick={(event) => this.handleHistoryItemClick(historyItem, event)}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.3)";
                    e.currentTarget.style.transform = "scale(1.05)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.15)";
                    e.currentTarget.style.transform = "scale(1)";
                  }}
                >
                  <div>
                    <CogIcon className="h-6 w-6 inline-block mr-2" aria-hidden="true" />
                    {this.state.editingHistoryItem === historyItem ? (
                      <input
                        className="edit-label-input"
                        type="text"
                        value={this.state.newLabel}
                        onChange={this.handleLabelChange}
                        onBlur={this.saveLabel}
                        onClick={(e) => e.stopPropagation()}
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            this.saveLabel();
                          }
                        }}
                      />
                    ) : (
                      <>
                        <p
                          className="inline-block"
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleLabelEdit(historyItem);
                          }}
                        >
                          {historyItem.label || historyItem.api.split("/").pop()}
                        </p>
                        <button
                          style={{
                            backgroundColor: "#007bff",
                            color: "white",
                            border: "none",
                            padding: "5px 10px",
                            marginLeft: "10px",
                            borderRadius: "5px",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleLabelEdit(historyItem);
                          }}
                        >
                          Edit Label
                        </button>
                      </>
                    )}
                  </div>
                  <div>
                    <p className="inline-block">{moment(historyItem.created).format("MMMM D")}</p>
                  </div>
                  <button className="delete-button" onClick={() => this.openConfirmationModal(historyItem._id)}>
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderFolders() {
    const { folders, newFolderName } = this.state;

    return (
      <div className="folders-container">
        <h2 className="create-folder-title">Folders</h2>
        <div className="create-folder create-folder-input">
          <input type="text" placeholder="New Folder Name" value={newFolderName} onChange={this.handleFolderNameChange} />
          <button className="create-folder-button" onClick={this.createFolder}>
            Create Folder
          </button>
        </div>
        {folders.map((folder) => (
          <div
            key={folder._id}
            className={`folder ${folder.isHighlighted ? "highlighted" : ""}`}
            onDragOver={this.handleDragOver}
            onDrop={(event) => this.handleDrop(event, folder._id, "folder")}
            onClick={() => this.handleFolderClick(folder)}
          >
            <FolderIcon className="h-6 w-6 inline-block mr-2" aria-hidden="true" />
            <h3 className="inline-block">{folder.name}</h3>
            <span className="folder-count">{folder.items.length}</span>
            {folder.isHighlighted && <span className="plus-sign">+</span>}
            <button
              className="delete-folder-button"
              onClick={(e) => {
                e.stopPropagation();
                this.deleteFolder(folder._id);
              }}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    );
  }

  render() {
    this.debugLog("Render state", {
      historyLength: this.state.history.length,
      foldersLength: this.state.folders.length,
      folderContents: this.state.folders.map((f) => ({ name: f.name, itemCount: f.items.length })),
    });

    if (this.state.loading) {
      return this.renderLoad();
    }

    const hasItems = this.state.history.length > 0 || this.state.folders.some((folder) => folder.items.length > 0);

    if (!hasItems) {
      return this.renderNoHistory();
    }

    const { showConfirmationModal } = this.state;

    return (
      <div className="user-history-container">
        <div className="left-panel">{this.renderFolders()}</div>
        <div className="right-panel">
          <div className="multi-select-container">
            <button className="multi-select-button" onClick={this.toggleMultiSelect}>
              {this.state.multiSelectMode ? "Disable" : "Enable"} Multi-Select
            </button>
            <div className="help-text">
              <p>Drag and drop histories into folders. Use the button to select multiple histories to drag.</p>
            </div>
          </div>
          {this.state.history.length > 0 ? (
            this.renderHistory()
          ) : (
            <p>All items are in folders. Use the folder view to access your history.</p>
          )}
          {this.renderHistoryDetails()}
          {this.renderFolderDetails()}
          <Modal
            isOpen={showConfirmationModal}
            onRequestClose={this.closeConfirmationModal}
            contentLabel="Confirm Delete"
            className="confirmation-modal"
            overlayClassName="react-modal-overlay"
          >
            <h2>Are you sure you want to delete this history?</h2>
            <div className="confirmation-buttons">
              <button className="confirm-button" onClick={this.confirmDeleteHistory}>
                Yes
              </button>
              <button className="cancel-button" onClick={this.closeConfirmationModal}>
                No
              </button>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default UserHistory;
